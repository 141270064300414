import { useEffect } from 'react';
import {
  DocumentScannerConfiguration,
  TextConfiguration
} from 'scanbot-web-sdk/@types/model/configuration/document-scanner-configuration';
import { z } from 'zod';
import { getScanbotSdk } from '../_utils/scanbot';

// Define the DetectionStatus enum/object schema based on the hint texts
const DetectionStatusSchema = z.enum([
  'OK',
  'OK_SmallSize',
  'OK_BadAngles',
  'OK_BadAspectRatio',
  'OK_OffCenter',
  'Error_NothingDetected',
  'Error_Brightness',
  'Error_Noise'
]);

// The Polygon type is mentioned but its structure is not specified
const PolygonSchema = z.unknown();

// Main document detection schema
const DocumentDetectionSchema = z
  .object({
    detectionStatus: DetectionStatusSchema,
    success: z.boolean(),
    original: z.instanceof(Uint8Array).optional(), // Explicitly mentioned as Uint8Array
    cropped: z.instanceof(Uint8Array).optional(), // Explicitly mentioned as Uint8Array
    polygon: PolygonSchema,
    averageBrightness: z.number().min(0).max(255)
  })
  .and(z.record(z.string(), z.unknown())); // Allow additional unknown properties

export type DocumentDetection = z.infer<typeof DocumentDetectionSchema>;
export type DetectionStatus = z.infer<typeof DetectionStatusSchema>;

const videoConstraints: MediaTrackConstraints = {
  facingMode: 'environment'
  // aspectRatio: 4 / 5
};

type Props = {
  text: TextConfiguration;
  onNewPage: (d: DocumentDetection) => void;
};

let documentScannerDestructor = () => {
  console.error('Scanner did not start properly');
};

export default function Scanner({ text, onNewPage }: Props) {
  const scannerConfig: DocumentScannerConfiguration = {
    containerId: 'document-scanner-container',
    text,
    videoConstraints,
    onDocumentDetected: (result) => {
      // This is on the perimeter of the library that does not support typing
      // so we add on our own types
      onNewPage(DocumentDetectionSchema.parse(result));
    }
  };

  // Initialize scanbot in this use effect
  useEffect(() => {
    getScanbotSdk()
      .then((sbsdk) => sbsdk.createDocumentScanner(scannerConfig))
      .then((scanner) => {
        // For proper disposal when the component is destructed
        documentScannerDestructor = () => {
          scanner.dispose();
        };
      })
      .catch(console.error);

    return () => {
      documentScannerDestructor();
      documentScannerDestructor = () => {
        console.error('Scanner did not start properly');
      };
    };
  }, []);

  return <div id="document-scanner-container" className="max-h-full"></div>;
}
