import classNames from 'classnames';
import { FC } from 'react';
import SpinnerIcon from './icons/SpinnerIcon';

/**
 * @deprecated Use the shared component library
 * @param param0
 * @returns
 */
const Spinner: FC<{ className?: string }> = ({ className }) => {
  return (
    <div className="relative">
      <SpinnerIcon
        className={classNames(
          'w-10 aspect-square animate-spin icon-inactive fill-blue-500',
          className
        )}
      />
    </div>
  );
};

export default Spinner;
