import { ValidationError } from '@tanstack/react-form-old';
import classNames from 'classnames';
import { E164Number } from 'libphonenumber-js';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input';

/**
 * @deprecated Use the shared component library
 * @param param0
 * @returns
 */
const PhoneNumberInput: FC<{
  value: string;
  fieldName: string;
  required?: boolean;
  error?: ValidationError;
  subtitleText?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange: (value?: E164Number) => void;
  onBlur?: (event: React.FocusEvent<HTMLElement, Element>) => void;
  containerClassName?: string;
  dataTestId?: string;
}> = ({
  value,
  fieldName,
  required,
  error,
  subtitleText,
  placeholder,
  disabled,
  onChange,
  onBlur,
  containerClassName,
  dataTestId
}) => {
  const { t } = useTranslation();
  return (
    <div className={classNames('flex flex-col space-y-2', containerClassName)}>
      <label htmlFor={fieldName} className="hidden">
        {t('Phone Number')}
      </label>

      <PhoneInput
        data-test-id={dataTestId}
        defaultCountry="US"
        placeholder={placeholder ?? t('Phone Number')}
        name={fieldName}
        onChange={onChange}
        value={value}
        onBlur={onBlur}
        disabled={disabled}
        className={classNames(
          'border-b-[0.5px] h-8 px-2 space-x-2 outline-none placeholder:text-inactive',
          {
            'bg-secondary *:bg-secondary text-tertiary': disabled // We can't use the pseudo class :disabled here since the library doesn't seem to apply it on the input directly
          }
        )}
      />

      <div className="flex flex-row">
        {subtitleText && !error && (
          <p className="text-body-3 ml-2 text-tertiary">{subtitleText}</p>
        )}

        {required && !error && (
          <p className="text-body-3 ml-2 text-tertiary italic">
            {' '}
            {t('Required')}
          </p>
        )}

        {error && <p className="text-body-3 ml-2 text-negative">{error}</p>}
      </div>
    </div>
  );
};

export default PhoneNumberInput;
