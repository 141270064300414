import { z } from "zod";

// Deprecate this when there is a good way to extract prisma types
export const FormType = z.union([
  z.literal("PRIVACY_POLICY"),
  z.literal("CONTACT_ACKNOWLEDGEMENT"),
  z.literal("PREQUAL"),
  z.literal("HARD_CREDIT_APPLICATION"),
  z.literal("HARD_CREDIT_APPLICATION_MODIFICATION"),
  z.literal("IDENTITY_THEFT_PREVENTION"),
  z.literal("IDENTITY_VERIFICATION"),
  z.literal("TEST_DRIVE_DISCLOSURE"),
  z.literal("DMV_DOCUMENTS"),
  z.literal("CUSTOM"),
]);
export type FormType = z.TypeOf<typeof FormType>;

export const FinanceType = z.union([
  z.literal("CASH"),
  z.literal("LEASE"),
  z.literal("FINANCE"),
  z.literal("OUTSIDE_FINANCING"),
  z.literal("UNDECIDED"),
]);
export type FinanceType = z.TypeOf<typeof FinanceType>;

export const IdCardType = z.union([
  z.literal("DRIVERS_LICENSE"),
  z.literal("STATE_ID"),
  z.literal("OTHER"),
]);
export type IdCardType = z.TypeOf<typeof IdCardType>;

// Base dashboard user role
const BaseUserRoleSchema = z.union([
  z.literal("ADMIN"),
  z.literal("FNI_MANAGER"),
  z.literal("BDC"),
  z.literal("SALES_MANAGER"),
  z.literal("SALES_PERSON"),
]);

// User roles is all role includes the customer
export const UserRole = z.union([BaseUserRoleSchema, z.literal("CUSTOMER")]);
export type UserRole = z.TypeOf<typeof UserRole>;

// Dashboard user roles only include dashboard roles
export const DashboardUserRoleSchema = BaseUserRoleSchema;
export type DashboardUserRole = z.TypeOf<typeof DashboardUserRoleSchema>;

export const VehicleConditionLiterals = [
  z.literal("EXCELLENT"),
  z.literal("GOOD"),
  z.literal("SATISFACTORY"),
  z.literal("UNSATISFACTORY"),
] as const;
export const VehicleConditionType = z.union(VehicleConditionLiterals);
export type VehicleConditionType = z.TypeOf<typeof VehicleConditionType>;

export const IdDocumentInfo = z.object({
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  middleName: z.string().optional(),
  suffix: z.string().optional(),
  cityInAddress: z.string().optional(),
  zipCodeInAddress: z.string().optional(),
  stateInAddress: z.string().optional(),
  stateName: z.string().optional(),
  documentNumber: z.string().optional(),
  expirationDate: z.string().optional(),
  dateOfBirth: z.string().optional(),
  dateOfIssue: z.string().optional(),
  idType: z.string().optional(),
  endorsements: z.string().optional(),
  veteran: z.string().optional(),
  restrictions: z.string().optional(),
  class: z.string().optional(),
  address: z.string().optional(),
  county: z.string().optional(),
  placeOfBirth: z.string().optional(),
  mrzCode: z.string().optional(),
});
export type IdDocumentInfo = z.TypeOf<typeof IdDocumentInfo>;

export const AddressHousingStatusType = z.union([
  z.literal("OWNING"),
  z.literal("RENTING"),
  z.literal("LIVING_WITH_RELATIVES"),
  z.literal("OTHER"),
]);
export type AddressHousingStatusType = z.infer<typeof AddressHousingStatusType>;

export const EmploymentStatus = z.union([
  z.literal("EMPLOYED"),
  z.literal("SELF_EMPLOYED"),
  z.literal("STUDENT"),
  z.literal("RETIRED"),
  z.literal("UNEMPLOYED"),
]);
export type EmploymentStatus = z.TypeOf<typeof EmploymentStatus>;

export const IncomeInterval = z.union([
  z.literal("YEARLY"),
  z.literal("MONTHLY"),
  z.literal("WEEKLY"),

  // Deprecated
  z.literal("BI_WEEKLY"),
]);
export type IncomeInterval = z.TypeOf<typeof IncomeInterval>;

export const TransactionStatusSchema = z.union([
  z.literal("REGISTERED"),
  z.literal("SIGNED_UP"),
  z.literal("IN_PROGRESS"),

  z.literal("PREQUAL_SUBMITTED"),
  z.literal("CREDIT_APP_SUBMITTED"),
  z.literal("FILE_COMPLETE"),
  z.literal("READY_FOR_FNI"),

  z.literal("DELIVERED"),

  // Deprecate
  z.literal("WEB_LEAD"), // For web prequal applications
  z.literal("WEB_APPLICATION"), // For web hard credit applications
  z.literal("COMPLETED", { description: "DEPRECATED" }),
  z.literal("DISASSOCIATED", { description: "DEPRECATED" }),
  z.literal("DEAD", { description: "DEPRECATED" }),
  z.literal("RULE_90_DAYS", { description: "DEPRECATED" }),
  z.literal("NOT_STARTED", { description: "DEPRECATED" }),
]);
export type TransactionStatusSchema = z.infer<typeof TransactionStatusSchema>;

export const TransactionSource = z.union([
  z.literal("KIOSK"),
  z.literal("ADMIN_DASHBOARD"),
  z.literal("WEB_PREQUAL"),
  z.literal("WEB_APPLICATION"),
]);
export type TransactionSource = z.infer<typeof TransactionSource>;

export const VehicleBodyTypeSchema = z.union([
  z.literal("SEDAN", { description: "Sedan" }),
  z.literal("SUV", { description: "SUV" }),
  z.literal("COUPE", { description: "Coupe" }),
  z.literal("MINIVAN", { description: "Minivan" }),
  z.literal("OTHER", { description: "Other" }),
]);

export const CdkDealJacketStatus = z.union([
  z.literal("STAGED_FOR_PUSHING"),
  z.literal("PUSHED"),
]);
export type CdkDealJacketStatus = z.infer<typeof CdkDealJacketStatus>;

export const VehicleRegistrationAddressAnswer = z.union([
  z.literal("SAME"),
  z.literal("DIFFERENT"),
]);

export type VehicleRegistrationAddressAnswer = z.infer<
  typeof VehicleRegistrationAddressAnswer
>;
