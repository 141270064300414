import classNames from 'classnames';
import { FC, useState } from 'react';
import { v4 } from 'uuid';
import CheckMarkIcon from '../icons/CheckMarkIcon';

/**
 * @deprecated: Use Checkbox from shared component library instead
 * @returns
 */
const Checkbox: FC<{
  value: boolean;
  onChange: () => void;
  inputId?: string;
  disabled?: boolean;
  readOnly?: boolean;
  variant?: 'DEFAULT' | 'RADIO_BUTTON';
  inputClassName?: string;
  containerClassName?: string;
  dataTestId?: string;
}> = ({
  value,
  onChange,
  inputId,
  disabled,
  readOnly,
  variant = 'DEFAULT',
  inputClassName,
  containerClassName,
  dataTestId
}) => {
  const [id] = useState(() => inputId ?? v4());

  return (
    // The test-data-id should ideally be on the input elemeht, but that element
    // is hidden so that does not work.
    // TODO: Refactor to make this more transparent
    <div className={classNames('flex', containerClassName)}>
      <input
        id={id}
        type="checkbox"
        checked={value}
        disabled={disabled}
        className="hidden"
        onChange={() => onChange()}
        readOnly={readOnly}
      />

      {variant === 'DEFAULT' && (
        <label
          data-test-id={dataTestId}
          htmlFor={id}
          className={classNames(
            'flex items-center justify-center rounded size-4',
            // We can't use the pseudo-class :disabled because we have to style the label and not the input itself
            {
              'cursor-pointer border-primary': !disabled,
              'cursor-not-allowed border-inactive': disabled,
              border: !value,
              'bg-interactive-primary': value && !disabled,
              'bg-primary-inverse': value && disabled
            },
            inputClassName
          )}
        >
          {value && <CheckMarkIcon className="size-2.5 icon-inverse" />}
        </label>
      )}

      {variant === 'RADIO_BUTTON' && (
        <label
          data-test-id={dataTestId}
          htmlFor={id}
          className={classNames(
            'flex items-center justify-center rounded-full size-5',
            // We can't use the pseudo-class :disabled because we have to style the label and not the input itself
            {
              'cursor-pointer': !disabled,
              'cursor-not-allowed border-inactive': disabled,
              'border-2': !value,
              'border-[0.375rem]': value,
              'border-interactive': value && !disabled,
              'border-primary': !value && !disabled
            }
          )}
        />
      )}
    </div>
  );
};

export default Checkbox;
