import { TFunction } from 'i18next';
import Image from 'image-js';

export const getHumanReadableFormAndDocumentType = (
  roleEnum: string,
  t: TFunction
) => {
  const formTypeMap: { [key: string]: string } = {
    PRIVACY_POLICY: t('Privacy Policy'),
    CONTACT_ACKNOWLEDGEMENT: t('Contact Acknowledgement'),
    IDENTITY_THEFT_PREVENTION: t('Identity Theft Prevention'),
    IDENTITY_VERIFICATION: t('Identity Verification'),
    PREQUAL: t('Prequal'),
    HARD_CREDIT_APPLICATION: t('Credit Application'),
    HARD_CREDIT_APPLICATION_MODIFICATION: t('Credit Application Modification'),
    TEST_DRIVE_DISCLOSURE: t('Test Drive Disclosure'),
    UTILITY_BILLS: t('Utility Bills'),
    PROOF_OF_INCOME: t('Proof of Income'),
    BANK_STATEMENTS: t('Bank Statements'),
    DMV_DOCUMENTS: t('DMV Documents'),
    PRE_PURCHASE: t('Pre Purchase'),
    POST_PURCHASE: t('Post Purchase'),
    OTHER: t('Other')
  };

  return formTypeMap[roleEnum] ?? roleEnum;
};

export const cropCardHolderImage = async (file: File) => {
  const image = (await Image.load(URL.createObjectURL(file))).rotateRight(); // When we load the image with image-js, it has it in landscape mode, so we have to rotate it
  const width = image.width;
  const height = image.height;
  const croppedImageBlob = await image
    .crop({
      // X and Y are in the top left of the image
      x: 0, // The card should have no offset on the left
      y: height * 0.06, // The card holder has a little offset at the top
      width: width * 0.87,
      height: height * 0.92
    })
    .rotateRight()
    .toBlob('image/jpeg');

  return new File([croppedImageBlob], 'cropped_image.jpeg', {
    type: 'image/jpeg'
  });
};
