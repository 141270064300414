import { CalendarTodayOutlined } from '@mui/icons-material';
import { parseISO } from 'date-fns';
import { FC, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InputBase from '..';
import { InputBackgroundType } from '../inputConfig';

const DateInput: FC<{
  value: string;
  label: string;
  assistiveMessage?: string;
  errorMessage?: string;
  placeholder: string;
  onChange?: (date: string) => void;
  onBlur?: (() => void) | undefined;
  required: boolean;
  disabled: boolean;
  className?: string;
  backgroundType?: InputBackgroundType;
  dataTestId?: string;
}> = ({
  value,
  label,
  assistiveMessage,
  errorMessage,
  placeholder,
  onChange,
  onBlur,
  required,
  disabled,
  className,
  backgroundType = 'DARK',
  dataTestId
}) => {
  const [isOpen, setIsOpen] = useState(false);

  // We can further customize the calendar popover when we have designs for it: https://reactdatepicker.com/#example-calendar-container
  return (
    <InputBase
      inputComponent={
        <DatePicker
          selected={value.length ? parseISO(value) : null}
          onChange={(updatedDate: Date | null) => {
            onChange?.(updatedDate?.toISOString() ?? '');
          }}
          isClearable={false}
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          placeholderText={placeholder}
          onFocus={() => {
            setIsOpen(true);
          }}
          onBlur={() => {
            setIsOpen(false);
            onBlur?.();
          }}
          customInput={<input data-test-id={dataTestId} type="text" />}
          className="w-full bg-transparent !pl-spacing-07 !py-[6px] body-01 outline-none"
          wrapperClassName="w-full"
          calendarIconClassName="!size-6 !p-spacing-02 icon-secondary"
          calendarClassName="bg-primary"
          icon={<CalendarTodayOutlined className="size-6" fontSize="inherit" />}
          showIcon
        />
      }
      label={label}
      assistiveMessage={assistiveMessage}
      errorMessage={errorMessage}
      required={required}
      disabled={disabled}
      backgroundType={backgroundType}
      isBeingUpdated={isOpen}
      className={className}
    />
  );
};

export default DateInput;
