import { captureException } from '@sentry/react';
import { useForm } from '@tanstack/react-form-old';
import { useQuery } from '@tanstack/react-query';
import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { VehicleType } from '~/__generated__/backend/zeus';
import updateVehicleAction from '~/actions/vehicles/updateVehicleAction';
import Button from '~/components/Button';
import Header from '~/components/Header';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import DropDown from '~/components/inputs/DropDown';
import NumberInput, {
  parseNumberInputValue
} from '~/components/inputs/NumberInput';
import TextInput from '~/components/inputs/TextInput';
import Spinner from '~/components/Spinner';
import stateOptions from '~/config/formSelectionOptions/stateOptions';
import vehicleConditionOptions from '~/config/formSelectionOptions/vehicleConditionOptions';
import { gqlQueryClient } from '~/lib/backend';
import {
  stringToVehicleBodyType,
  stringToVehicleCondition
} from '~/lib/enumMap';
import { queryClient } from '~/main';
import { resetMeQuery } from '~/queries/meQuery';
import {
  VehicleType as Vehicle,
  vehicleSelector
} from '~/querySelectors/vehicle';
import { Link, useNavigate, useParams } from '~/router';

const TradeVehicleInformationForm: FC<{
  tradeVehicle?: Vehicle;
  isSubmitting: boolean;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ tradeVehicle, isSubmitting, setIsSubmitting }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { transactionId, vehicleId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/information'
  );
  const handleGoBack = () => {
    navigate(
      '/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/registrationCard',
      { params: { transactionId, vehicleId, dealershipSlug } }
    );
  };
  const form = useForm({
    defaultValues: {
      vin: tradeVehicle?.vin ?? '',
      model: tradeVehicle?.model ?? '',
      make: tradeVehicle?.make ?? '',
      year: tradeVehicle?.year ?? '',
      registrationName: tradeVehicle?.registrationName ?? '',
      registrationState: tradeVehicle?.registrationState ?? '',
      color: tradeVehicle?.color ?? '',
      bodyType: tradeVehicle?.bodyType,
      condition: tradeVehicle?.condition,
      mileage: tradeVehicle?.mileage?.toString() ?? ''
    },
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);

        await updateVehicleAction(transactionId, VehicleType.TRADE, vehicleId, {
          ...values,
          mileage: parseNumberInputValue(values.mileage)
        });
        await Promise.all([
          resetMeQuery(),
          queryClient.resetQueries({
            queryKey: ['transaction', transactionId]
          })
        ]);

        navigate(
          '/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/payoff',
          {
            params: { transactionId, vehicleId, dealershipSlug }
          }
        );
      } catch (error) {
        captureException(error);
        console.error(error);

        if (error instanceof Error && error.message) {
          toast.error(error.message);
        } else {
          toast.error('Failed to save vehicle data');
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  const vehicleBodyTypeOptions = [
    { value: 'SEDAN', label: t('Sedan') },
    { value: 'SUV', label: t('SUV') },
    { value: 'COUPE', label: t('Coupe') },
    { value: 'MINIVAN', label: t('Minivan') },
    { value: 'OTHER', label: t('Other') }
  ];

  return (
    <Fragment>
      <form.Provider>
        <form
          className="flex flex-col space-y-12 overflow-y-scroll max-w-full overflow-x-hidden"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            void form.handleSubmit();
          }}
          onChange={() => {}}
        >
          <div className="space-y-6">
            <form.Field name="vin">
              {(field) => {
                return (
                  <TextInput
                    fieldName={field.name}
                    value={field.state.value}
                    labelText={t('VIN#')}
                    placeholder={t('VIN#')}
                    subtitleText={t('VIN#')}
                    disabled={isSubmitting}
                    required
                    error={field.state.meta.touchedErrors.at(0)}
                    onChange={(e) => {
                      field.handleChange(e.target.value);
                    }}
                    maxLength={17}
                  />
                );
              }}
            </form.Field>

            <form.Field name="make">
              {(field) => {
                return (
                  <TextInput
                    fieldName={field.name}
                    value={field.state.value}
                    labelText={t('Make')}
                    placeholder={t('Make')}
                    subtitleText={t('Make')}
                    disabled={isSubmitting}
                    required
                    error={field.state.meta.touchedErrors.at(0)}
                    onChange={(e) => {
                      field.handleChange(e.target.value);
                    }}
                  />
                );
              }}
            </form.Field>

            <div className="grid grid-cols-2 gap-6 items-end">
              <form.Field name="model">
                {(field) => {
                  return (
                    <TextInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText={t('Model')}
                      placeholder={t('Model')}
                      subtitleText={t('Model')}
                      disabled={isSubmitting}
                      required
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => {
                        field.handleChange(e.target.value);
                      }}
                    />
                  );
                }}
              </form.Field>

              <form.Field name="year">
                {(field) => {
                  return (
                    <TextInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText={t('Year')}
                      placeholder={t('Year')}
                      subtitleText={t('Year')}
                      disabled={isSubmitting}
                      required
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => {
                        field.handleChange(e.target.value);
                      }}
                    />
                  );
                }}
              </form.Field>

              <form.Field name="bodyType">
                {(field) => {
                  return (
                    <DropDown
                      fieldName={field.name}
                      value={field.state.value ?? ''}
                      labelText={t('Body Type')}
                      subtitleText={t('Body Type')}
                      placeholder={t('Body Type')}
                      options={vehicleBodyTypeOptions}
                      disabled={isSubmitting}
                      required
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => {
                        field.handleChange(
                          stringToVehicleBodyType(e.target.value)
                        );
                      }}
                    />
                  );
                }}
              </form.Field>

              <form.Field name="condition">
                {(field) => {
                  return (
                    <DropDown
                      fieldName={field.name}
                      value={field.state.value ?? ''}
                      labelText={t('Condition')}
                      placeholder={t('Condition')}
                      subtitleText={t('Condition')}
                      options={vehicleConditionOptions}
                      disabled={isSubmitting}
                      required
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => {
                        field.handleChange(
                          stringToVehicleCondition(e.target.value)
                        );
                      }}
                    />
                  );
                }}
              </form.Field>

              <form.Field name="color">
                {(field) => {
                  return (
                    <TextInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText={t('Color')}
                      subtitleText={t('Color')}
                      placeholder={t('Color')}
                      disabled={isSubmitting}
                      required
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => {
                        field.handleChange(e.target.value);
                      }}
                    />
                  );
                }}
              </form.Field>

              <form.Field name="mileage">
                {(field) => {
                  return (
                    <NumberInput
                      value={field.state.value}
                      fieldName={field.name}
                      subtitleText={t('Mileage')}
                      placeholder={t('Mileage')}
                      disabled={isSubmitting}
                      required
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => {
                        field.handleChange(e.target.value);
                      }}
                    />
                  );
                }}
              </form.Field>
            </div>

            <form.Field name="registrationName">
              {(field) => {
                return (
                  <TextInput
                    fieldName={field.name}
                    value={field.state.value}
                    labelText={t('Registration Name')}
                    subtitleText={t('Registration Name')}
                    placeholder={t('Registration Name')}
                    disabled={isSubmitting}
                    required
                    error={field.state.meta.touchedErrors.at(0)}
                    onChange={(e) => {
                      field.handleChange(e.target.value);
                    }}
                  />
                );
              }}
            </form.Field>

            <form.Field name="registrationState">
              {(field) => {
                return (
                  <DropDown
                    fieldName={field.name}
                    value={field.state.value}
                    labelText={t('Registration State')}
                    subtitleText={t('Registration State')}
                    placeholder={t('Registration State')}
                    options={stateOptions}
                    disabled={isSubmitting}
                    required
                    error={field.state.meta.touchedErrors.at(0)}
                    onChange={(e) => {
                      field.handleChange(e.target.value);
                    }}
                  />
                );
              }}
            </form.Field>
          </div>
        </form>
      </form.Provider>

      <div className="flex flex-row justify-between">
        <Button
          variant="TERTIARY"
          disabled={isSubmitting}
          onClick={handleGoBack}
        >
          {t('Back')}
        </Button>

        <form.Subscribe
          selector={(state) => [state.values]}
          children={([values]) => {
            const canContinue =
              !!values.vin &&
              !!values.make &&
              !!values.model &&
              !!values.year &&
              !!values.bodyType &&
              !!values.condition &&
              !!values.color &&
              !!values.mileage &&
              !!values.registrationName &&
              !!values.registrationState;

            return (
              <Button
                onClick={() => {
                  void form.handleSubmit();
                }}
                loading={isSubmitting}
                disabled={!canContinue}
              >
                {t('Next')}
              </Button>
            );
          }}
        />
      </div>
    </Fragment>
  );
};

const TradeVehicleInformationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { transactionId, vehicleId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/information'
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleCancel = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: { transactionId, dealershipSlug }
    });
  };
  const { data, isLoading } = useQuery({
    queryKey: ['transaction', transactionId, 'tradeVehicle', vehicleId],
    queryFn: async () =>
      gqlQueryClient()({
        transaction: [
          {
            id: transactionId
          },
          {
            tradeVehicle: vehicleSelector
          }
        ]
      })
  });

  return (
    <div className="flex flex-col h-dvh">
      <Header
        title={t('Trade Registration')}
        leftElement={
          <button
            className=" text-primary-brand"
            onClick={handleCancel}
            disabled={isSubmitting}
          >
            {t('Cancel')}
          </button>
        }
        totalSteps={4}
        currentStep={2}
      />

      <div className="flex w-full px-6 justify-center z-30">
        <div className="flex w-full max-w-screen-md self-center pt-4">
          <Link
            to="/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/registrationCard"
            params={{ transactionId, vehicleId, dealershipSlug }}
            className="flex w-6"
          >
            <div className="relative">
              <ChevronRightIcon className="w-6 -scale-x-100 icon-tertiary" />
            </div>
          </Link>
        </div>
      </div>

      <div className="flex flex-col items-center overflow-y-scroll px-6">
        <div className="flex flex-col max-w-screen-md py-10 space-y-12 justify-between w-full">
          <div className="space-y-8 md:space-y-16">
            <div className="space-y-4">
              <h2>{t('Step 2: Vehicle Information')}</h2>

              <p>
                {t(
                  'Please fill in or verify the information in the fields below.'
                )}
              </p>
            </div>

            {isLoading && (
              <div className="flex flex-1 items-center justify-center">
                <Spinner />
              </div>
            )}

            {!isLoading && (
              <TradeVehicleInformationForm
                tradeVehicle={data?.transaction?.tradeVehicle}
                isSubmitting={isSubmitting}
                setIsSubmitting={setIsSubmitting}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradeVehicleInformationPage;
