import classNames from 'classnames';
import { FC } from 'react';

/**
 *
 * @param totalSteps: Total number of steps. Starting from 1.
 * @param currentStep: Current step. Starting from 1.
 * @returns
 */
const ProgressBar: FC<{
  totalSteps: number;
  currentStep: number;
}> = ({ totalSteps, currentStep }) => {
  return (
    <div className="flex flex-row space-x-spacing-02">
      {Array.from({ length: totalSteps }, (_, index) => (
        <div
          key={index}
          className={classNames('size-2.5 rounded-full', {
            // The steps start from 1 but the index from 0
            'bg-interactive': index + 1 <= currentStep,
            'bg-tertiary': index + 1 > currentStep
          })}
        />
      ))}
    </div>
  );
};

export default ProgressBar;
