import { ChevronLeft } from '@mui/icons-material';
import { useForm } from '@tanstack/react-form';
import { Button, TextInput } from '@thedealersconcierge/components';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CustomerTransactionRole,
  FormSubmissionAccessEmum
} from '~/__generated__/backend/zeus';
import Checkbox from '~/components/inputs/Checkbox';
import { gqlMutationClient } from '~/lib/backend';
import { useNavigate } from '~/router';
import { getScanbotSdk } from '../_utils/scanbot';
import { DocumentDetection } from './Scanner';

type Props = {
  pages: DocumentDetection[];
  onNavBack: () => void;
  transactionId: string;

  /**
   * If the submission token is set, this will be used for authenticating the
   * submission, otherwise we use the internal authentication token.
   *
   * The spirit is:
   *
   * 1. submissionToken is used when a user comes from the dealer dash
   * 2. internal authentication is used when the user comes from the consumer app
   */
  submissionToken: string;
};

export const Review: FC<Props> = ({
  pages,
  onNavBack,
  transactionId,
  submissionToken
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const form = useForm<{
    documentTitle: string;
    access: 'DEALERSHIP' | 'BOTH';
    addTo: 'BUYER' | 'CO_BUYER';
  }>({
    defaultValues: {
      documentTitle: '',
      access: 'DEALERSHIP',
      addTo: 'BUYER'
    },
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);

        const generator = await (
          await getScanbotSdk()
        ).beginPdf({
          standardPaperSize: 'A4',
          pageDirection: 'PORTRAIT',
          pageFit: 'FIT_IN',
          dpi: 72,
          jpegQuality: 80,
          resample: false
        });

        for (const page of pages) {
          if (page.cropped) {
            await generator.addPage(page.cropped);
          }
        }

        const pdfContents = await generator.complete();
        const blob = new Blob([pdfContents], { type: `application/pdf` });

        const addTo =
          values.value.addTo === 'BUYER'
            ? CustomerTransactionRole.BUYER
            : CustomerTransactionRole.CO_BUYER;

        const makeUploadRes = await gqlMutationClient()({
          makeUploadForScanner: [
            {
              submissionToken,
              addTo,
              transactionId
            },
            {
              __typename: true,
              '...on GraphQLError': {
                message: true
              },
              '...on MutationMakeUploadForScannerSuccess': {
                data: {
                  fileId: true,
                  uploadUrl: true
                }
              }
            }
          ]
        });

        if (
          makeUploadRes.makeUploadForScanner?.__typename !==
          'MutationMakeUploadForScannerSuccess'
        ) {
          throw new Error(
            `Failed to get upload URL ${makeUploadRes.makeUploadForScanner?.message ?? ''}`
          );
        }

        const fileId = makeUploadRes.makeUploadForScanner.data.fileId;
        const uploadUrl = makeUploadRes.makeUploadForScanner.data.uploadUrl;

        if (!fileId || !uploadUrl) {
          throw new Error('Some Error happened when provisioning the file');
        }

        await fetch(uploadUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': `application/pdf`,
            'Content-Length': blob.size.toString()
          },
          body: blob
        });

        await gqlMutationClient()({
          addScanToDealerJacket: [
            {
              transactionId,
              submissionToken,
              addTo,
              fileId,
              title: values.value.documentTitle,
              access:
                values.value.access === 'DEALERSHIP'
                  ? FormSubmissionAccessEmum.DEALERSHIP
                  : FormSubmissionAccessEmum.BOTH
            },
            {
              __typename: true,
              '...on GraphQLError': {
                message: true
              },
              '...on MutationAddScanToDealerJacketSuccess': {
                data: {
                  status: true
                }
              }
            }
          ]
        });

        navigate('/scanner/done');
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  return (
    <form
      className="flex flex-col justify-stretch h-full"
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        void form.handleSubmit();
      }}
    >
      <div className="bg-secondary h-14 flex justify-between px-6">
        <div className="items-center flex" onClick={onNavBack}>
          <ChevronLeft className="text-primary-brand" />
        </div>
        <div className="items-center justify-center flex">Review</div>
        <div></div>
      </div>
      <div className="flex-grow-0 h-[230px] bg-primary p-6">
        <form.Field name="documentTitle">
          {(field) => (
            <TextInput
              label={t('Document Name')}
              placeholder={t('Eg. Bank Statement')}
              value={field.state.value}
              disabled={false}
              required={true}
              backgroundType="LIGHT"
              onChange={(e) => {
                field.handleChange(e);
              }}
            />
          )}
        </form.Field>

        <div className="flex justify-between">
          <form.Field name="access">
            {(field) => (
              <div className="flex flex-col space-y-2">
                <div
                  className="flex space-x-4 cursor-pointer"
                  onClick={() => {
                    field.setValue('DEALERSHIP');
                  }}
                >
                  {/* The new component needs to support radio button */}
                  <Checkbox
                    variant="RADIO_BUTTON"
                    onChange={() => {
                      field.setValue('DEALERSHIP');
                    }}
                    value={field.state.value === 'DEALERSHIP'}
                  />
                  <div>{t('Dealership')}</div>
                </div>

                <div
                  className="flex space-x-4 cursor-pointer"
                  onClick={() => {
                    field.setValue('BOTH');
                  }}
                >
                  {/* The new component needs to support radio button */}
                  <Checkbox
                    variant="RADIO_BUTTON"
                    onChange={() => {
                      field.setValue('BOTH');
                    }}
                    value={field.state.value === 'BOTH'}
                  />
                  <div>{t('Dealership & Customer')}</div>
                </div>
              </div>
            )}
          </form.Field>

          <form.Field name="addTo">
            {(field) => (
              <div className="flex flex-col space-y-2">
                <div
                  className="flex space-x-4 cursor-pointer"
                  onClick={() => {
                    field.setValue('BUYER');
                  }}
                >
                  {/* The new component needs to support radio button */}
                  <Checkbox
                    variant="RADIO_BUTTON"
                    onChange={() => {
                      field.setValue('BUYER');
                    }}
                    value={field.state.value === 'BUYER'}
                  />
                  <div>{t('Add to Buyer')}</div>
                </div>

                <div
                  className="flex space-x-4 cursor-pointer"
                  onClick={() => {
                    field.setValue('CO_BUYER');
                  }}
                >
                  {/* The new component needs to support radio button */}
                  <Checkbox
                    variant="RADIO_BUTTON"
                    onChange={() => {
                      field.setValue('CO_BUYER');
                    }}
                    value={field.state.value === 'CO_BUYER'}
                  />
                  <div>{t('Add to Co-buyer')}</div>
                </div>
              </div>
            )}
          </form.Field>
        </div>
      </div>
      <div className="overflow-x-auto flex-grow bg-tertiary">
        <div className="flex h-full space-x-8 mx-8 items-center">
          {pages.map((page) => {
            if (!page.cropped) {
              return;
            }
            const blob = new Blob([page.cropped], {
              type: `application/png`
            });
            const url = window.URL.createObjectURL(blob);
            return (
              <div className="w-64 flex-none">
                <img src={url} className="w-72 aspect-[9/16] object-cover" />
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex items-center justify-end pr-6 h-24 flex-grow-0 bg-secondary">
        <Button
          isLoading={isSubmitting}
          disabled={isSubmitting}
          label="Upload"
          onClick={() => {
            void form.handleSubmit();
          }}
        />
      </div>
    </form>
  );
};
