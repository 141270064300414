import classNames from 'classnames';
import { FC, MouseEventHandler, ReactElement } from 'react';
import Spinner from './Spinner';

/**
 * @deprecated Use the shared component library
 * @param param0
 * @returns
 */
const Button: FC<{
  children: ReactElement | string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  loading?: boolean;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  variant?: 'PRIMARY' | 'SECONDARY' | 'TERTIARY' | 'DESTRUCTIVE' | 'TEXT_ONLY';
  size?: 'BIG' | 'DEFAULT' | 'SMALL';
  className?: string;
  dataTestId?: string;
}> = ({
  children,
  onClick,
  disabled,
  loading,
  type,
  variant = 'PRIMARY',
  size = 'DEFAULT',
  className,
  dataTestId
}) => {
  return (
    <button
      data-test-id={dataTestId}
      type={type}
      className={classNames(
        'relative disabled:opacity-40 disabled:hover:opacity-40 disabled:cursor-not-allowed',
        {
          'button-primary': variant === 'PRIMARY',
          'button-secondary': variant === 'SECONDARY',
          'button-tertiary': variant === 'TERTIARY',
          'button-destructive': variant === 'DESTRUCTIVE',
          'button-base text-primary-brand font-medium': variant === 'TEXT_ONLY',
          'w-40': size === 'BIG',
          'text-sm py-4 h-10 px-8': size === 'BIG' || size === 'DEFAULT',
          'text-xs p-2 h-8': size === 'SMALL'
        },
        className
      )}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {!loading ? (
        children
      ) : (
        <div className="flex absolute top-0 right-0 bottom-0 left-0 justify-center">
          <Spinner
            className={classNames({
              'w-4': size === 'BIG' || size === 'DEFAULT',
              'w-2': size === 'SMALL'
            })}
          />
        </div>
      )}
    </button>
  );
};

export default Button;
