import { format, parse, parseISO } from "date-fns";

/**
 * Returns the date after neutralizing the timezone offset
 *
 * @param dateTimeString: date time string with time and timezone component
 * @param format: format of the input date time string, default "yyyy-MM-dd"
 * @returns
 */
export const dateTimeStringToDate = (
  dateTimeString: string,
  format?: string
) => {
  const date = parse(dateTimeString, format ?? "yyyy-MM-dd", new Date());
  const timezoneOffset = date.getTimezoneOffset() * 60_000;

  return new Date(date.getTime() - timezoneOffset); // We have to neutralize the offset, because when saving dates, the time component is simply stripped
};

/**
 * Returns the date after neutralizing the timezone offset
 *
 * @param isoString: ISO date time string
 * @returns
 */
export const isoStringToDateWithoutTimeZone = (isoString: string) => {
  const date = parseISO(isoString);
  const timezoneOffset = date.getTimezoneOffset() * 60_000;

  return new Date(date.getTime() - timezoneOffset); // We have to neutralize the offset, because when saving dates, the time component is simply stripped
};

/**
 * Neutralizes the timezone offset on a Date
 *
 * @param dateTime
 * @returns
 */
export const dateTimeToDate = (dateTime: Date) => {
  const dateString = format(dateTime, "yyyy-MM-dd");

  return dateTimeStringToDate(dateString);
};

/**
 * Converts a Date to a date string for saving it in and retrieving it from the database
 *
 * @param date
 * @returns
 */
export const dateToString = (date: Date) => {
  return format(date, "yyyy-MM-dd");
};
