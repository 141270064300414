import { captureException } from '@sentry/react';
import { useForm } from '@tanstack/react-form';
import { useQuery } from '@tanstack/react-query';
import { zodValidator, ZodValidator } from '@tanstack/zod-form-adapter';
import { Button, ProgressBar, Spinner } from '@thedealersconcierge/components';
import classNames from 'classnames';
import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import CreditApplicationConsentForm, {
  CreditApplicationConsentFormValues,
  ValidCreditApplicationConsentSchema
} from '~/components/forms/CreditApplicationConsentForm';
import Header from '~/components/Header';
import { gqlMutationClient, gqlQueryClient } from '~/lib/backend';
import { resetCustomerQuery } from '~/queries/customerQuery';
import dealershipQuery from '~/queries/dealershipQuery';
import meQuery from '~/queries/meQuery';
import { useNavigate, useParams } from '~/router';

const Forms: FC<{
  transactionId: string;
  userId: string;
  dealershipSlug: string;
  dealershipName: string;
  ipAddress: string;
}> = ({ transactionId, userId, dealershipSlug, dealershipName, ipAddress }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const consentForm = useForm<CreditApplicationConsentFormValues, ZodValidator>(
    {
      defaultValues: {
        hasAcceptedAcknowledgements: false,
        hasAcceptedElectronicDisclosure: false,
        hasAcceptedContactConsent: false,
        fullName: '',
        signature: ''
      },
      validators: {
        onSubmit: ValidCreditApplicationConsentSchema(t, 'IN_APP')
      },
      validatorAdapter: zodValidator(),
      onSubmitInvalid: () => {
        toast.error(t('Please acknowledge and provide your signature'));
      },
      onSubmit: async ({ value }) => {
        try {
          setIsSubmitting(true);

          const resp = await gqlMutationClient()({
            createAndSubmitHardCreditApplication: [
              {
                transactionId,
                fullName: value.fullName,
                signature: value.signature,
                deviceId: window.navigator.userAgent,
                ipAddress,
                hasAcceptedAcknowledgements: value.hasAcceptedAcknowledgements,
                hasAcceptedElectronicDisclosure:
                  value.hasAcceptedElectronicDisclosure
              },
              {
                __typename: true,
                '...on GraphQLError': {
                  message: true
                },
                '...on MutationCreateAndSubmitHardCreditApplicationSuccess': {
                  data: {
                    status: true
                  }
                }
              }
            ]
          });

          if (
            resp.createAndSubmitHardCreditApplication?.__typename ===
            'GraphQLError'
          ) {
            throw new Error(resp.createAndSubmitHardCreditApplication.message);
          }

          await resetCustomerQuery(transactionId, userId);
          navigate('/dashboard/:dealershipSlug/:transactionId', {
            params: { dealershipSlug, transactionId }
          });
        } catch (e) {
          captureException(e);
          console.error(e);
          toast.error(t('An unexpected error happened'));
        } finally {
          setIsSubmitting(false);
        }
      }
    }
  );

  return (
    <Fragment>
      <CreditApplicationConsentForm
        dealershipSlug={dealershipSlug}
        dealershipName={dealershipName}
        form={consentForm}
        isSubmitting={isSubmitting}
        type="IN_APP"
        dataTestId="credit-application-acknowledgement-form"
      />

      <div
        className={classNames(
          'col-span-4 tablet:col-span-6 desktop:col-span-8 tablet:col-start-2 desktop:col-start-3', // Parent grid layout
          'flex flex-row justify-end pt-spacing-04'
        )}
      >
        <Button
          label={t('Submit')}
          isLoading={isSubmitting}
          onClick={() => void consentForm.handleSubmit()}
          data-test-id="credit-application-submit-button"
        />
      </div>
    </Fragment>
  );
};

const Acknowledgements = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dealershipSlug, transactionId } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/credit-application/acknowledgements'
  );
  const { data: meData, isFetched: fetchedMeData } = useQuery(meQuery());
  const userId = meData?.me?.user?.id;
  const { data: dealership, isFetched: fetchedDealership } = useQuery(
    dealershipQuery({ slug: dealershipSlug })
  );
  const { data: publicData, isFetched: fetchedPublicData } = useQuery({
    queryKey: ['public'],
    queryFn: () => {
      return gqlQueryClient()({
        public: {
          ipAddress: true
        }
      });
    }
  });
  const handleGoBack = () => {
    navigate(
      '/dashboard/:dealershipSlug/:transactionId/credit-application/employment',
      {
        params: {
          dealershipSlug,
          transactionId
        }
      }
    );
  };
  const handleCancel = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: {
        dealershipSlug,
        transactionId
      }
    });
  };

  return (
    <div className="relative flex flex-col items-center bg-primary h-full max-h-screen overflow-y-scroll">
      <Header
        title={t('Credit Application')}
        className="w-full"
        leftElement={
          <Button
            label={t('Back')}
            variant="GHOST"
            size="LARGE"
            onClick={handleGoBack}
          />
        }
        rightElement={
          <Button
            label={t('Cancel')}
            variant="GHOST"
            size="LARGE"
            onClick={handleCancel}
          />
        }
      />

      <div
        className={classNames(
          'mobile-screen-grid tablet:tablet-screen-grid desktop:desktop-screen-grid', // Grid layout
          'size-full space-y-spacing-05 tablet:space-y-spacing-06 py-spacing-05 tablet:py-spacing-06'
        )}
      >
        <div
          className={classNames(
            'col-span-4 tablet:col-span-8 desktop:col-span-10 desktop:col-start-2', // Parent grid layout
            'w-full relative flex flex-row justify-center'
          )}
        >
          <ProgressBar totalSteps={3} currentStep={3} />
        </div>

        {fetchedDealership &&
          fetchedPublicData &&
          dealership?.dealership?.name &&
          userId && (
            <Forms
              transactionId={transactionId}
              userId={userId}
              dealershipSlug={dealershipSlug}
              dealershipName={dealership.dealership.name}
              ipAddress={publicData?.public?.ipAddress ?? 'unknown'}
            />
          )}

        {(!fetchedDealership || !fetchedPublicData || !fetchedMeData) && (
          <div
            className={classNames(
              'col-span-4 tablet:col-span-8 desktop:col-span-10 desktop:col-start-2', // Parent grid layout
              'w-full h-96 relative flex flex-row justify-center items-center'
            )}
          >
            <Spinner color="GREY" size="LARGE" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Acknowledgements;
