import { ValidationError } from '@tanstack/react-form-old';
import classNames from 'classnames';
import { FC } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

/**
 * @deprecated Use the shared component library
 * @param param0
 * @returns
 */
const DateInput: FC<{
  value: Date | null;
  fieldName: string;
  subtitleText?: string;
  error?: ValidationError;
  disabled?: boolean;
  required?: boolean;
  inputClassName?: string;
  containerClassName?: string;
  onChange?: (date: Date | null) => void;
  dataTestId?: string;
  placeholderText?: string;
}> = ({
  value,
  fieldName,
  subtitleText,
  error,
  disabled,
  required,
  inputClassName,
  containerClassName,
  onChange,
  dataTestId,
  placeholderText
}) => {
  const { t } = useTranslation();
  return (
    <div className={classNames('flex flex-col space-y-2', containerClassName)}>
      <DatePicker
        name={fieldName}
        selected={value}
        onChange={onChange}
        dateFormat="MM-dd-yyyy"
        isClearable
        showYearDropdown
        showMonthDropdown
        dropdownMode="select"
        placeholderText={placeholderText}
        className={twMerge(
          'bg-transparent w-full text-body-1 h-8 px-2 border-b-[0.5px]',
          inputClassName
        )}
        customInput={<input data-test-id={dataTestId} type="text" />}
      />

      <div className="flex flex-row">
        {subtitleText && !error && (
          <p
            className={classNames('text-body-3 ml-2 text-tertiary', {
              'text-inactive': disabled // We can use the "disabled:" TailwindCSS only on inputs
            })}
          >
            {subtitleText}
          </p>
        )}

        {required && !error && (
          <p
            className={classNames('text-body-3 ml-2 text-tertiary italic', {
              'text-inactive': disabled // We can use the "disabled:" TailwindCSS only on inputs
            })}
          >
            {t('Required')}
          </p>
        )}

        {error && <p className="text-body-3 ml-2 text-negative">{error}</p>}
      </div>
    </div>
  );
};

export default DateInput;
