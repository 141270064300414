import { captureException } from '@sentry/react';
import { parsePhoneNumberWithError } from 'libphonenumber-js';
import cloneDeep from 'lodash/cloneDeep';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import createAddressAction from '~/actions/addresses/createAddressAction';
import updateAddressAction from '~/actions/addresses/updateAddressAction';
import submitHardCreditApplicationModificationAction from '~/actions/formSubmissions/hardCreditApplication/submitHardCreditApplicationModificationAction';
import Button from '~/components/Button';
import { gqlMutationClient } from '~/lib/backend';
import { getDateFromUnkown } from '~/lib/utils';
import { DealershipQueryType } from '~/queries/dealershipQuery';
import { resetHardCreditQuery } from '~/queries/hardCreditQuery';
import { MeQueryType, resetMeQuery } from '~/queries/meQuery';
import { AddressType } from '~/querySelectors/address';
import { EmploymentType } from '~/querySelectors/employment';
import { HardCreditApplicationType } from '~/querySelectors/hardCreditApplication';
import { useNavigate, useParams } from '~/router';
import AddressForm from './AddressForm';
import BasicInformationForm from './BasicInformationForm';
import EmploymentForm from './EmploymentForm';
import PersonalReferenceForm from './PersonalReferenceForm';

const ModificationForm: FC<{
  meData: MeQueryType;
  addresses: AddressType[];
  employments: EmploymentType[];
  dealership: DealershipQueryType;
  hardCreditApplication: HardCreditApplicationType;
}> = ({
  meData,
  addresses,
  employments,
  dealership,
  hardCreditApplication
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // This is a section component
  const { transactionId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/modification/modificationForm'
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  // We use the hard credit application object to retrieve basic information and personal references
  const [updatedHardCreditApplication, setUpdatedHardCreditApplication] =
    useState(cloneDeep(hardCreditApplication));
  const handleSaveBasicInformation = (values: {
    firstName: string;
    lastName: string;
    middleName: string;
    birthdate: Date;
    socialSecurityNumber: string;
    driversLicenseNumber: string;
    driversLicenseState: string;
    homePhoneNumber: string;
    phoneNumber: string;
    email: string;
  }) => {
    setUpdatedHardCreditApplication((prev) => {
      return {
        ...prev,
        ...values
      };
    });
  };
  const handleSavePersonalReference = async (values: {
    personalReferenceFirstName: string;
    personalReferenceMiddleName: string;
    personalReferenceLastName: string;
    personalReferenceRelationship: string;
    personalReferencePhoneNumber: string;
    personalReferenceAddressStreet: string;
    personalReferenceAddressApartmentDetails: string;
    personalReferenceAddressZipCode: string;
    personalReferenceAddressCity: string;
    personalReferenceAddressState: string;
  }) => {
    const hasAddressValues =
      values.personalReferenceAddressStreet.length ||
      values.personalReferenceAddressApartmentDetails.length ||
      values.personalReferenceAddressZipCode.length ||
      values.personalReferenceAddressCity.length ||
      values.personalReferenceAddressState.length;
    const updatedAddressValues = {
      street: values.personalReferenceAddressStreet,
      apartmentDetails: values.personalReferenceAddressApartmentDetails,
      zipCode: values.personalReferenceAddressZipCode,
      city: values.personalReferenceAddressCity,
      state: values.personalReferenceAddressState
    };

    let updatedAddress: AddressType | undefined = undefined;

    // Check if there is an existing address for the personal reference
    if (hardCreditApplication.personalReferenceAddress?.id) {
      updatedAddress = await updateAddressAction(
        hardCreditApplication.personalReferenceAddress.id,
        updatedAddressValues
      );
    } else if (hasAddressValues) {
      // There is no existing address so we have to create one
      updatedAddress = await createAddressAction(updatedAddressValues);
    }

    setUpdatedHardCreditApplication((prev) => {
      return {
        ...prev,
        personalReferenceFirstName: values.personalReferenceFirstName,
        personalReferenceMiddleName: values.personalReferenceMiddleName,
        personalReferenceLastName: values.personalReferenceLastName,
        personalReferenceRelationship: values.personalReferenceRelationship,
        personalReferencePhoneNumber: values.personalReferencePhoneNumber,
        personalReferenceAddress: updatedAddress
      };
    });
  };
  const handleSubmitEdits = useCallback(async () => {
    try {
      setIsSubmitting(true);

      if (!meData.me?.user) {
        throw new Error('User not available');
      }

      if (!dealership.dealership) {
        throw new Error('Dealership not available');
      }

      const formSubmission =
        await submitHardCreditApplicationModificationAction(
          transactionId,
          meData.me.user,
          dealership.dealership,
          updatedHardCreditApplication,
          addresses,
          employments,
          meData.me.ipAddress ?? 'No IP address',
          t
        );

      // The GraphQL Zeus type is not correct. It only specifies string | undefined for the fields but the API also returns null.
      // Therefore, we have to explicitly set undefined as a default value
      const resp = await gqlMutationClient()({
        createHardCreditApplication: [
          {
            transactionId,
            hardCreditApplication: {
              firstName: updatedHardCreditApplication.firstName ?? undefined,
              lastName: updatedHardCreditApplication.lastName ?? undefined,
              middleName: updatedHardCreditApplication.middleName ?? undefined,
              birthdate:
                getDateFromUnkown(updatedHardCreditApplication.birthdate) ??
                undefined,
              socialSecurityNumber:
                updatedHardCreditApplication.socialSecurityNumber ?? undefined,
              driversLicenseNumber:
                updatedHardCreditApplication.driversLicenseNumber ?? undefined,
              driversLicenseState:
                updatedHardCreditApplication.driversLicenseState ?? undefined,
              homePhoneNumber:
                updatedHardCreditApplication.homePhoneNumber &&
                updatedHardCreditApplication.homePhoneNumber.length > 0
                  ? parsePhoneNumberWithError(
                      updatedHardCreditApplication.homePhoneNumber
                    ).number
                  : undefined,
              phoneNumber:
                updatedHardCreditApplication.phoneNumber &&
                updatedHardCreditApplication.phoneNumber.length > 0
                  ? parsePhoneNumberWithError(
                      updatedHardCreditApplication.phoneNumber
                    ).number
                  : undefined,
              email: updatedHardCreditApplication.email ?? undefined,
              personalReferenceFirstName:
                updatedHardCreditApplication.personalReferenceFirstName ??
                undefined,
              personalReferenceMiddleName:
                updatedHardCreditApplication.personalReferenceMiddleName ??
                undefined,
              personalReferenceLastName:
                updatedHardCreditApplication.personalReferenceLastName ??
                undefined,
              personalReferenceRelationship:
                updatedHardCreditApplication.personalReferenceRelationship ??
                undefined,
              personalReferencePhoneNumber:
                updatedHardCreditApplication.personalReferencePhoneNumber &&
                updatedHardCreditApplication.personalReferencePhoneNumber
                  .length > 0
                  ? parsePhoneNumberWithError(
                      updatedHardCreditApplication.personalReferencePhoneNumber
                    ).number
                  : undefined,
              personalReferenceAddressId:
                updatedHardCreditApplication.personalReferenceAddress?.id ??
                undefined,
              signatureId:
                updatedHardCreditApplication.signature?.id ?? undefined,
              formSubmissionId: formSubmission.id ?? undefined
            }
          },
          {
            __typename: true,
            '...on GraphQLError': {
              message: true
            },
            '...on MutationCreateHardCreditApplicationSuccess': {
              data: {
                id: true
              }
            }
          }
        ]
      });

      if (
        !resp.createHardCreditApplication ||
        resp.createHardCreditApplication.__typename === 'GraphQLError'
      ) {
        throw new Error(
          resp.createHardCreditApplication?.message ?? 'Could not create HCA'
        );
      }

      await Promise.all([resetMeQuery(), resetHardCreditQuery(transactionId)]);

      navigate('/dashboard/:dealershipSlug/:transactionId', {
        params: { transactionId, dealershipSlug }
      });
    } catch (e) {
      captureException(e);
      console.error(e);
      toast.error('An unexpected error happened');
    } finally {
      setIsSubmitting(false);
    }
  }, [
    transactionId,
    meData,
    dealership,
    addresses,
    employments,
    updatedHardCreditApplication,
    navigate
  ]);
  const moreThanTwoYearsAddressHistory =
    addresses
      .map((a) => (a.durationYears ?? 0) + (a.durationMonths ?? 0) / 12)
      .reduce((total, duration) => duration + total, 0) >= 2;
  const moreThanTwoYearsEmploymentHistory =
    employments
      .map((e) => (e.durationYears ?? 0) + (e.durationMonths ?? 0) / 12)
      .reduce((total, duration) => duration + total, 0) >= 2;

  return (
    <div className="flex flex-col w-full space-y-10">
      <BasicInformationForm
        hardCreditApplication={updatedHardCreditApplication}
        onSave={handleSaveBasicInformation}
      />

      <AddressForm
        addresses={addresses}
        transactionId={transactionId}
        hasMoreThanTwoYearsHistory={moreThanTwoYearsAddressHistory}
      />

      <EmploymentForm
        employments={employments}
        transactionId={transactionId}
        hasMoreThanTwoYearsHistory={moreThanTwoYearsEmploymentHistory}
      />

      <PersonalReferenceForm
        hardCreditApplication={updatedHardCreditApplication}
        onSave={(v) => void handleSavePersonalReference(v)}
      />

      <div className="flex w-full px-6 justify-center">
        <div className="flex flex-row justify-end w-full max-w-screen-md">
          <Button
            onClick={() => void handleSubmitEdits()}
            loading={isSubmitting}
            disabled={
              !moreThanTwoYearsAddressHistory ||
              !moreThanTwoYearsEmploymentHistory
            }
          >
            {t('Submit Edits')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ModificationForm;
