import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import classNames from 'classnames';
import { FC, useRef } from 'react';
import { CheckboxSize } from './checkboxConfig';

/**
 * Checkboxes are used when users can select multiple items from a list.
 *
 * Use large size checkbox for Kiosk (tablet) and mobile devices.
 *
 * The value is of type boolean.
 *
 * The onChange handler accepts an updated boolean value.
 *
 * Implementing the data-test-id is still in TODO. Now, it might work with setting it on the input directly.
 *
 * TODO: Implement radio buttons
 *
 * @returns
 */
const Checkbox: FC<{
  checked: boolean;
  onChange: (checked: boolean) => void;
  size: CheckboxSize;
  disabled: boolean;
}> = ({
  checked,
  onChange,
  size,
  disabled // We are not provide a default value to make a conscious decision on it
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <label
      className={classNames('flex relative', {
        'size-5': size === 'SMALL',
        'size-8': size === 'LARGE',
        'opacity-40': disabled
      })}
      onClick={() => {
        if (!disabled) {
          onChange(!checked);
        }
      }}
    >
      <input
        ref={inputRef}
        defaultChecked={checked}
        className={classNames(
          'absolute opacity-0 cursor-pointer disabled:cursor-not-allowed',
          {
            'size-5': size === 'SMALL',
            'size-8': size === 'LARGE'
          }
        )}
        disabled={disabled}
      />

      {!checked && (
        <CheckBoxOutlineBlank
          className={classNames(
            'icon-primary rounded focus:ring-inset focus:ring-blue-800',
            {
              'size-5 focus:ring-2': size === 'SMALL',
              'size-8 focus:ring-[3.2px]': size === 'LARGE'
            }
          )}
        />
      )}

      {checked && (
        <CheckBox
          className={classNames(
            'icon-interactive rounded focus:ring-inset focus:ring-blue-800',
            {
              'size-5 focus:ring-2': size === 'SMALL',
              'size-8 focus:ring-[3.2px]': size === 'LARGE'
            }
          )}
        />
      )}
    </label>
  );
};

export default Checkbox;
