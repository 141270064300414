import { ValidationError } from '@tanstack/react-form-old';
import classNames from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ChevronRightIcon from '../icons/ChevronRightIcon';

type DropDownOption = {
  value: string;
  label: string;
  disabled?: boolean;
};

const DropDown: FC<{
  value: string;
  fieldName: string;
  labelText: string;
  options: DropDownOption[];
  subtitleText?: string;
  inputClassName?: string;
  containerClassName?: string;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  disabled?: boolean;
  placeholder?: string;
  error?: ValidationError;
  required?: boolean;
  variant?: 'FORM' | 'STAND_ALONE';
  dataTestId?: string;
}> = ({
  value,
  fieldName,
  labelText,
  inputClassName,
  containerClassName,
  onChange,
  disabled,
  placeholder,
  options,
  subtitleText,
  error,
  required,
  variant = 'FORM',
  dataTestId
}) => {
  const { t } = useTranslation();
  return (
    <div className={classNames('flex flex-col space-y-2', containerClassName)}>
      <div
        className={classNames('relative flex flex-row z-0', {
          'border-b-[0.5px] border-inactive text-body-1': variant === 'FORM',
          'border border-secondary rounded text-body-3':
            variant === 'STAND_ALONE'
        })}
      >
        <label htmlFor={fieldName} className="hidden">
          {labelText}
        </label>

        <select
          data-test-id={dataTestId}
          name={fieldName}
          className={classNames(
            'w-full h-8 block px-2 appearance-none outline-none rounded-none bg-transparent disabled:disabled-input',
            {
              'text-inactive': value === ''
            },
            inputClassName
          )}
          onChange={onChange}
          value={value !== '' ? value : 'placeholder'}
          disabled={disabled}
        >
          {placeholder && (
            <option value="placeholder" disabled>
              {placeholder}
            </option>
          )}

          {options.map((option) => {
            return (
              <option
                key={option.value}
                value={option.value}
                disabled={option.disabled}
              >
                {option.label}
              </option>
            );
          })}
        </select>

        <div className="absolute top-0 right-2 bottom-0">
          <ChevronRightIcon
            className={classNames('w-4 rotate-90 icon-tertiary', {
              'icon-inactive': disabled // We can use the "disabled:" TailwindCSS only on inputs
            })}
          />
        </div>
      </div>

      {(subtitleText || error || required) && (
        <div className="flex flex-row">
          {subtitleText && !error && (
            <p
              className={classNames('text-body-3 ml-2 text-tertiary', {
                'text-inactive': disabled // We can use the "disabled:" TailwindCSS only on inputs
              })}
            >
              {subtitleText}
            </p>
          )}

          {required && !error && (
            <p
              className={classNames('text-body-3 ml-2 text-tertiary italic', {
                'text-inactive': disabled // We can use the "disabled:" TailwindCSS only on inputs
              })}
            >
              {t('Required')}
            </p>
          )}

          {error && <p className="text-body-3 ml-2 text-negative">{error}</p>}
        </div>
      )}
    </div>
  );
};

export default DropDown;
