import { FormSubmissionDataSchema } from '@thedealersconcierge/lib/codecs/schema/formSubmissionData';
import { format } from 'date-fns';
import { TFunction } from 'i18next';
import { parsePhoneNumber } from 'libphonenumber-js';
import employmentStatusOptions from '~/config/formSelectionOptions/employmentStatusOptions';
import housingStatusOptions from '~/config/formSelectionOptions/housingStatusOptions';
import incomeIntervalOptions from '~/config/formSelectionOptions/incomeIntervalOptions';
import stateOptions from '~/config/formSelectionOptions/stateOptions';
import { AddressType } from '~/querySelectors/address';
import { DealershipType } from '~/querySelectors/dealership';
import { EmploymentType } from '~/querySelectors/employment';
import { HardCreditApplicationType } from '~/querySelectors/hardCreditApplication';
import { UserType } from '~/querySelectors/userSelector';

const generateHardCreditApplicationFormSubmissionData = (
  user: UserType,
  dealership: DealershipType,
  hardCreditApplication: HardCreditApplicationType,
  addresses: AddressType[],
  employments: EmploymentType[],
  signature: string,
  ipAddress: string,
  t: TFunction,
  signatureName?: string
): Extract<
  FormSubmissionDataSchema,
  { submissionType: 'HARD_CREDIT_APPLICATION' }
> => {
  const currentAddress = addresses.find((a) => a.timelinePosition === 0);
  const previousAddress = addresses.find((a) => a.timelinePosition === 1);
  const currentEmployment = employments.find((e) => e.timelinePosition === 0);
  const previousEmployment = employments.find((e) => e.timelinePosition === 1);

  return {
    submissionType: 'HARD_CREDIT_APPLICATION',
    submissionData: {
      firstName: hardCreditApplication.firstName ?? '',
      middleName: hardCreditApplication.middleName ?? '',
      lastName: hardCreditApplication.lastName ?? '',
      streetAddress: currentAddress?.street ?? '',
      city: currentAddress?.city ?? '',
      state:
        stateOptions.find((so) => so.value === currentAddress?.state)?.label ??
        '',
      zip: currentAddress?.zipCode ?? '',
      birthdate: hardCreditApplication.birthdate
        ? format(
            new Date(String(hardCreditApplication.birthdate)),
            'MM/dd/yyyy'
          )
        : '',
      socialSecurityNumber: hardCreditApplication.socialSecurityNumber ?? '',
      phoneNumber: hardCreditApplication.phoneNumber
        ? parsePhoneNumber(hardCreditApplication.phoneNumber).format('NATIONAL')
        : '',
      residentialStatus:
        housingStatusOptions(t).find(
          (o) => o.value === currentAddress?.housingStatus
        )?.label ?? '',
      addressDuration: `${currentAddress?.durationYears ?? 0} Yrs. ${currentAddress?.durationMonths ?? 0} Mon.`,
      mortgageOrRentMonthlyPay: `${currentAddress?.monthlyPayment?.toLocaleString()} USD`,
      previousAddressLength: `${previousAddress?.durationYears ?? 0} Yrs. ${previousAddress?.durationMonths ?? 0} Mon.`,
      previousAddress: previousAddress?.fullAddress ?? '',
      employerName: currentEmployment?.employerName ?? '',
      employerPhoneNumber: currentEmployment?.employerPhoneNumber
        ? parsePhoneNumber(currentEmployment.employerPhoneNumber).format(
            'NATIONAL'
          )
        : '',
      jobTitle: currentEmployment?.occupation ?? '',
      employmentStatus:
        employmentStatusOptions(t).find(
          (o) => o.value === currentEmployment?.employmentStatus
        )?.label ?? '',
      timeOnJob: `${currentEmployment?.durationYears ?? 0} Yrs. ${currentEmployment?.durationMonths ?? 0} Mon.`,
      salary: currentEmployment?.incomeAmount
        ? `${currentEmployment.incomeAmount.toLocaleString()} USD`
        : '',
      type:
        incomeIntervalOptions(t).find(
          (o) => o.value === currentEmployment?.incomeInterval
        )?.label ?? '',
      prevEmployerInfo: previousEmployment
        ? `${previousEmployment.employerName ?? ''}, ${
            previousEmployment.employerPhoneNumber
              ? 'tel: ' +
                parsePhoneNumber(previousEmployment.employerPhoneNumber).format(
                  'NATIONAL'
                )
              : ''
          }, length: ${previousEmployment.durationYears ?? 0} Yrs. ${previousEmployment.durationMonths ?? 0} Mon.`.trim()
        : '',
      otherIncmeSource: currentEmployment?.otherIncomeSource ?? '',
      otherIncomeAmount: currentEmployment?.otherIncomeAmount
        ? `${currentEmployment.otherIncomeAmount.toLocaleString()} USD`
        : '',
      otherIncomeType:
        incomeIntervalOptions(t).find(
          (o) => o.value === currentEmployment?.otherIncomeInterval
        )?.label ?? '',
      refNameAddress: (
        `${hardCreditApplication.personalReferenceFirstName} ${hardCreditApplication.personalReferenceMiddleName} ${hardCreditApplication.personalReferenceLastName}`.trim() +
        `\n${hardCreditApplication.personalReferenceAddress?.fullAddress ?? ''}`
      ).trim(),
      refPhoneNumber: hardCreditApplication.personalReferencePhoneNumber
        ? parsePhoneNumber(
            hardCreditApplication.personalReferencePhoneNumber
          ).format('NATIONAL')
        : '',
      refRelationship:
        hardCreditApplication.personalReferenceRelationship ?? '',
      dealershipName: dealership.name ?? 'no-dealership-name',
      signature: signature,
      customerName:
        `${hardCreditApplication.firstName} ${hardCreditApplication.middleName} ${hardCreditApplication.lastName}`.trim(),

      // Compliance fields
      dateTime: format(new Date(), 'MM/dd/yyyy hh:mm a'),
      name:
        signatureName ??
        `${user.firstName ?? ''} ${user.lastName ?? ''}`.trim(),
      ipAddress,
      deviceId: window.navigator.userAgent
    }
  };
};

export default generateHardCreditApplicationFormSubmissionData;
