import { Dialog, Transition } from '@headlessui/react';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import CircleCheckMarkIcon from '~/components/icons/CircleCheckMarkIcon';

const ConfirmationModal: FC<{ show: boolean }> = ({ show }) => {
  const { t } = useTranslation();

  return (
    <Transition appear show={show} as={Fragment}>
      {/* Can not close */}
      <Dialog as="div" className="relative z-30" onClose={() => null}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={
                  'w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-6'
                }
              >
                <div
                  className="py-4 text-center text-heading-3"
                  data-test-id="web-prequal-success"
                >
                  <Dialog.Title>
                    {t('Your Credit Application Was Submitted Successfully!')}
                  </Dialog.Title>
                </div>

                <p className="flex justify-center">
                  <CircleCheckMarkIcon className="text-[#219653] w-8 h-8" />
                </p>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ConfirmationModal;
