import { captureException } from '@sentry/react';
import { useForm } from '@tanstack/react-form-old';
import { useQuery } from '@tanstack/react-query';
import { Tooltip } from '@thedealersconcierge/components';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { VehicleType } from '~/__generated__/backend/zeus';
import updateVehicleAction from '~/actions/vehicles/updateVehicleAction';
import Button from '~/components/Button';
import Header from '~/components/Header';
import Spinner from '~/components/Spinner';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import DropDown from '~/components/inputs/DropDown';
import TextInput from '~/components/inputs/TextInput';
import { gqlQueryClient } from '~/lib/backend';
import { queryClient } from '~/main';
import { resetMeQuery } from '~/queries/meQuery';
import {
  VehicleType as Vehicle,
  vehicleSelector
} from '~/querySelectors/vehicle';
import { Link, useNavigate, useParams } from '~/router';

const PurchaseVehicleForm: FC<{
  vehicle?: Vehicle;
  isSubmitting: boolean;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ vehicle, isSubmitting, setIsSubmitting }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { transactionId, vehicleId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/purchaseVehicle/:vehicleId'
  );
  const handleCancel = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: { transactionId, dealershipSlug }
    });
  };
  const form = useForm({
    defaultValues: {
      vin: vehicle?.vin ?? '',
      stockNumber: vehicle?.stockNumber ?? '',
      make: vehicle?.make ?? '',
      model: vehicle?.model ?? '',
      year: vehicle?.year ?? '',
      trim: vehicle?.trim ?? '',
      isUsed:
        vehicle?.isUsed !== undefined ? (vehicle.isUsed ? 'USED' : 'NEW') : ''
    },
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);

        await updateVehicleAction(
          transactionId,
          VehicleType.PURCHASE,
          vehicleId,
          {
            ...values,
            isUsed: values.isUsed === 'USED'
          }
        );

        await Promise.all([
          resetMeQuery(),
          queryClient.resetQueries({
            queryKey: ['transaction', transactionId]
          })
        ]);

        navigate('/dashboard/:dealershipSlug/:transactionId', {
          params: { transactionId, dealershipSlug }
        });
      } catch (error) {
        captureException(error);
        console.error(error);

        if (error instanceof Error && error.message) {
          toast.error(error.message);
        } else {
          toast.error('Failed to save vehicle data');
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  return (
    <form.Provider>
      <form
        className="space-y-4 md:space-y-6"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void form.handleSubmit();
        }}
        data-test-id={'vehicle-for-purchase-form'}
      >
        <form.Field name="vin">
          {(field) => {
            return (
              <TextInput
                fieldName={field.name}
                value={field.state.value}
                labelText={t('VIN#')}
                placeholder={t('VIN#')}
                subtitleText={t('VIN#')}
                disabled={isSubmitting}
                error={field.state.meta.touchedErrors.at(0)}
                onChange={(e) => {
                  field.handleChange(e.target.value);
                }}
                dataTestId="vehicle-for-purchase-vin-input"
                maxLength={17}
              />
            );
          }}
        </form.Field>

        <form.Field name="stockNumber">
          {(field) => {
            return (
              <TextInput
                fieldName={field.name}
                value={field.state.value}
                labelText={t('Stock Number')}
                placeholder={t('Stock Number')}
                subtitleText={t('Stock Number')}
                disabled={isSubmitting}
                error={field.state.meta.touchedErrors.at(0)}
                onChange={(e) => {
                  field.handleChange(e.target.value);
                }}
                dataTestId="vehicle-for-purchase-stock-input"
              />
            );
          }}
        </form.Field>

        <form.Field name="make">
          {(field) => {
            return (
              <TextInput
                fieldName={field.name}
                value={field.state.value}
                labelText={t('Make')}
                placeholder={t('Make')}
                subtitleText={t('Make')}
                disabled={isSubmitting}
                error={field.state.meta.touchedErrors.at(0)}
                onChange={(e) => {
                  field.handleChange(e.target.value);
                }}
                dataTestId="vehicle-for-purchase-make"
              />
            );
          }}
        </form.Field>

        <form.Field name="model">
          {(field) => {
            return (
              <TextInput
                fieldName={field.name}
                value={field.state.value}
                labelText={t('Model')}
                placeholder={t('Model')}
                subtitleText={t('Model')}
                disabled={isSubmitting}
                error={field.state.meta.touchedErrors.at(0)}
                onChange={(e) => {
                  field.handleChange(e.target.value);
                }}
                dataTestId="vehicle-for-purchase-model"
              />
            );
          }}
        </form.Field>

        <form.Field name="year">
          {(field) => {
            return (
              <TextInput
                fieldName={field.name}
                value={field.state.value}
                labelText={t('Year')}
                placeholder={t('Year')}
                subtitleText={t('Year')}
                disabled={isSubmitting}
                error={field.state.meta.touchedErrors.at(0)}
                onChange={(e) => {
                  field.handleChange(e.target.value);
                }}
                dataTestId="vehicle-for-purchase-year"
              />
            );
          }}
        </form.Field>

        <form.Field name="trim">
          {(field) => {
            return (
              <TextInput
                fieldName={field.name}
                value={field.state.value}
                labelText={t('Trim')}
                placeholder={t('Trim')}
                subtitleText={t('Trim')}
                disabled={isSubmitting}
                error={field.state.meta.touchedErrors.at(0)}
                onChange={(e) => {
                  field.handleChange(e.target.value);
                }}
                dataTestId="vehicle-for-purchase-trim"
              />
            );
          }}
        </form.Field>

        <form.Field name="isUsed">
          {(field) => {
            return (
              <DropDown
                fieldName={field.name}
                value={field.state.value}
                labelText={t('Condition')}
                placeholder={t('Condition')}
                subtitleText={t('Condition')}
                options={[
                  { value: 'USED', label: t('used') },
                  { value: 'NEW', label: t('new') }
                ]}
                disabled={isSubmitting}
                error={field.state.meta.touchedErrors.at(0)}
                onChange={(e) => {
                  field.handleChange(e.target.value);
                }}
                dataTestId="vehicle-for-purchase-isUsed-dropdown"
              />
            );
          }}
        </form.Field>

        <div className="flex flex-row justify-between pt-4">
          <Button
            variant="TERTIARY"
            type="button"
            disabled={isSubmitting}
            onClick={handleCancel}
            dataTestId="vehicle-for-purchase-cancel-button"
          >
            {t('Cancel')}
          </Button>

          <Tooltip
            anchor={
              <div>
                <Button
                  type="submit"
                  loading={isSubmitting}
                  disabled={vehicle?.updatedByDealership}
                  dataTestId="vehicle-for-purchase-submit-button"
                >
                  {t('Save')}
                </Button>
              </div>
            }
            hide={!vehicle?.updatedByDealership}
            content={
              <p>
                {t('Data has been entered by dealership and cannot be edited')}
              </p>
            }
          />
        </div>
      </form>
    </form.Provider>
  );
};

const PurchaseVehiclePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { transactionId, vehicleId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/purchaseVehicle/:vehicleId'
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data, isLoading } = useQuery({
    queryKey: ['transaction', transactionId, 'vehicle', vehicleId],
    queryFn: async () =>
      gqlQueryClient()({
        transaction: [
          {
            id: transactionId
          },
          {
            vehicle: vehicleSelector
          }
        ]
      })
  });
  const handleCancel = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: { transactionId, dealershipSlug }
    });
  };

  return (
    <div className="flex flex-col h-dvh">
      <Header
        title={t('Vehicle for Purchase')}
        leftElement={
          <button
            className=" text-primary-brand"
            onClick={handleCancel}
            disabled={isSubmitting}
          >
            {t('Cancel')}
          </button>
        }
      />

      <div className="flex w-full px-6 justify-center z-30">
        <div className="flex w-full max-w-screen-md self-center pt-4">
          <Link
            to="/dashboard/:dealershipSlug/:transactionId"
            params={{ transactionId, dealershipSlug }}
            className="flex w-6"
          >
            <div className="relative">
              <ChevronRightIcon className="w-6 -scale-x-100 icon-tertiary" />
            </div>
          </Link>
        </div>
      </div>

      <div
        className="flex flex-col items-center overflow-y-scroll px-6"
        data-test-id={'vehicle-for-purchase-body'}
      >
        <div className="flex flex-col max-w-screen-md py-6 md:py-10 space-y-8 md:space-y-16 w-full">
          <div className="space-y-5">
            <h2>{t('Vehicle for Purchase')}</h2>

            <p>
              {t(
                'Please fill in or verify the information in the fields below.'
              )}
            </p>
          </div>

          {isLoading && (
            <div className="flex w-full aspect-square justify-center items-center relative">
              <Spinner className="w-10 aspect-square" />
            </div>
          )}

          {!isLoading && (
            <PurchaseVehicleForm
              vehicle={data?.transaction?.vehicle}
              isSubmitting={isSubmitting}
              setIsSubmitting={setIsSubmitting}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PurchaseVehiclePage;
