import { ValidationError } from '@tanstack/react-form-old';
import classNames from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

// eslint-disable-next-line react-refresh/only-export-components
export const parseNumberInputValue = (
  value?: string | number
): number | undefined => {
  if (value && typeof value === 'string') {
    return parseFloat(value.replace(/[^\d.]/g, '')); // This replaces all non-numeric characters except for "."
  }

  if (value && typeof value === 'number') {
    return value;
  }

  return undefined;
};

/**
 * @deprecated Use the shared component library
 * @param param0
 * @returns
 */
const NumberInput: FC<{
  value: string;
  fieldName: string;
  prefix?: string;
  suffix?: string;
  required?: boolean;
  error?: ValidationError;
  labelText?: string;
  subtitleText?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  containerClassName?: string;
  dataTestId?: string;
}> = ({
  value,
  fieldName,
  prefix,
  suffix,
  required,
  error,
  labelText,
  subtitleText,
  placeholder,
  disabled,
  onChange,
  containerClassName,
  dataTestId
}) => {
  const { t } = useTranslation();
  return (
    <div className={classNames('flex flex-col space-y-2', containerClassName)}>
      <div
        className="relative flex flex-row border-b-[0.5px] z-0 text-body-1"
        data-test-id={dataTestId}
      >
        <label htmlFor={fieldName} className="hidden">
          {labelText}
        </label>

        <NumericFormat
          prefix={prefix}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          aria-placeholder={placeholder}
          disabled={disabled}
          decimalScale={2}
          thousandSeparator=","
          className="h-8 px-2 space-x-2 outline-none placeholder:text-inactive disabled:disabled-input bg-transparent"
          suffix={suffix}
        />
      </div>

      <div className="flex flex-row">
        {subtitleText && !error && (
          <p className="text-body-3 ml-2 text-tertiary">{subtitleText}</p>
        )}

        {required && !error && (
          <p className="text-body-3 ml-2 text-tertiary italic">
            {t('Required')}
          </p>
        )}

        {error && <p className="text-body-3 ml-2 text-negative">{error}</p>}
      </div>
    </div>
  );
};

export default NumberInput;
