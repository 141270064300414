import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { DropdownOption } from './dropdownConfig';

const Dropdown: FC<{
  options: DropdownOption[];
  onSelect: (option: DropdownOption) => Promise<void> | void;
  className?: string;
}> = ({ options, onSelect, className }) => {
  return (
    <div
      className={twMerge(
        'py-spacing-02 rounded-radius-02 bg-primary shadow-shadow-03 w-80 max-h-60 overflow-y-scroll',
        className
      )}
    >
      {options.map((option) => {
        return (
          <div
            key={option.value}
            onClick={() => {
              void onSelect(option);
            }}
            className="w-full px-spacing-04 hover:bg-gray-100 active:bg-gray-200 py-spacing-03 desktop:py-spacing-02 focus:outline focus:outline-2 focus:-outline-offset-2 outline-blue-800"
          >
            <p className="body-02 text-gray-900 text-start">{option.label}</p>
          </div>
        );
      })}
    </div>
  );
};

export default Dropdown;
