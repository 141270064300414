import {
  formatSSN,
  maskSSNRegex
} from '@thedealersconcierge/lib/utils/strings';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import InputBase from '..';
import { InputBackgroundType } from '../inputConfig';

const SocialSecurityNumberInput: FC<{
  value: string;
  label: string;
  assistiveMessage?: string;
  errorMessage?: string;
  placeholder: string;
  onChange: (value: string) => void;
  onBlur?: (() => void) | undefined;
  required: boolean;
  disabled: boolean;
  className?: string;
  backgroundType?: InputBackgroundType;
  dataTestId?: string;
}> = ({
  value,
  label,
  assistiveMessage,
  errorMessage,
  placeholder,
  onChange,
  onBlur,
  required,
  disabled,
  className,
  backgroundType = 'DARK',
  dataTestId
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isTyping, setIsTyping] = useState(false);
  const formatSSNAndUpdateValue = useCallback(
    (value: string) => {
      const result = formatSSN(value);

      onChange(result);

      return result;
    },
    [onChange]
  );
  const maskSSN = useCallback(
    (ssn?: string) => {
      if (isTyping || !ssn) {
        return ssn;
      }

      return maskSSNRegex(ssn);
    },
    [isTyping]
  );

  useEffect(() => {
    if (inputRef.current) {
      const input = inputRef.current;
      const handleInputEvent = (event: HTMLElementEventMap['input']) => {
        const target = event.target;

        if (target instanceof HTMLInputElement) {
          target.value = formatSSNAndUpdateValue(target.value);
        }
      };

      input.addEventListener('input', handleInputEvent);

      return () => {
        input.removeEventListener('input', handleInputEvent);
      };
    }
  }, [inputRef, formatSSNAndUpdateValue]);

  return (
    <InputBase
      inputComponent={
        <input
          data-test-id={dataTestId}
          ref={inputRef}
          type="text"
          placeholder={placeholder}
          className="body-01 w-full px-spacing-02 py-[6px] text-primary outline-none placeholder:text-tertiary disabled:text-tertiary bg-transparent"
          onChange={(e) => {
            onChange(e.target.value);
          }}
          value={isTyping ? value : maskSSN(value)}
          onBlur={() => {
            setIsTyping(false);
            onBlur?.();
          }}
          onFocus={() => {
            setIsTyping(true);
          }}
          disabled={disabled}
        />
      }
      label={label}
      assistiveMessage={assistiveMessage}
      required={required}
      disabled={disabled}
      isBeingUpdated={isTyping}
      errorMessage={errorMessage}
      backgroundType={backgroundType}
      className={className}
    />
  );
};

export default SocialSecurityNumberInput;
