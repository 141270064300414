import { useQuery } from '@tanstack/react-query';
import {
  DmvDocument,
  PrivacyPolicy,
  TestDriveDisclosure
} from '@thedealersconcierge/lib/codecs/schema/formSubmissionData';
import { generatePdf } from '@thedealersconcierge/lib/pdf-gen';
import classNames from 'classnames';
import { format } from 'date-fns';
import { useAtomValue } from 'jotai';
import { ChangeEvent, useCallback, useRef, useState } from 'react';
import * as ReactDeviceDetect from 'react-device-detect';
import { Trans, useTranslation } from 'react-i18next';
import { vehicleDiscolureTemplate } from '~/actions/doVehicleTestDriveDisclosureAction';
import { privacyDocument } from '~/actions/formSubmissions/privacyPolicy/uploadPrivacyPolicyAction';
import uploadFileAction from '~/actions/formSubmissions/uploadFileAction';
import logoutAction from '~/actions/logoutAction';
import Button from '~/components/Button';
import DesktopWebcamModal from '~/components/DesktopWebcamModal';
import Header from '~/components/Header';
import Modal from '~/components/Modal';
import Banner from '~/components/StandOut';
import CaptureIcon from '~/components/icons/CaptureIcon';
import Checkbox from '~/components/inputs/Checkbox';
import config from '~/config';
import { gqlMutationClient } from '~/lib/backend';
import { cropCardHolderImage } from '~/lib/files';
import { dmvDocument } from '~/lib/form/dmv-document';
import { loadFontBytes } from '~/lib/pdf';
import { queryClient } from '~/main';
import dealershipQuery from '~/queries/dealershipQuery';
import meQuery from '~/queries/meQuery';
import { useNavigate, useParams } from '~/router';
import { kioskDealershipAtom } from '~/state/kiosk';

export default function Step1() {
  const kioskMode = useAtomValue(kioskDealershipAtom);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  // We are declaring the variables here because ReactDeviceDetect returns true for both isMobile and isTablet and tablet devices
  const isMobile = ReactDeviceDetect.isMobile && !ReactDeviceDetect.isTablet;
  const isTablet = ReactDeviceDetect.isTablet;
  const mobileCameraInputRef = useRef<HTMLInputElement | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { transactionId, dealershipSlug } = useParams(
    '/onboarding/:dealershipSlug/:transactionId/step1'
  );
  const { data: meData } = useQuery(meQuery());
  const { data: dealership } = useQuery(
    dealershipQuery({ slug: dealershipSlug })
  );

  const [showCaptureIdAlert, setShowCaptureIdAlert] = useState(false);
  const [captureSide, setCaptureSide] = useState<'front' | 'back'>('front');
  const [isDesktopWebcamModalOpen, setIsDesktopWebcamModalOpen] =
    useState(false);
  const [captureFront, setCaptureFront] = useState<File | undefined>();
  const [captureBack, setCaptureBack] = useState<File | undefined>();
  const [contactConsent, setContactConsent] = useState(false);
  const [testDriveDisclosureAgreed, setTestDriveDisclosureAgreed] =
    useState(false);

  const [error, setError] = useState<string | null>(null);

  const handleCapture = (side: 'front' | 'back') => {
    setCaptureSide(side);
    setShowCaptureIdAlert(true);
  };
  const handleCaptureContinue = () => {
    setShowCaptureIdAlert(false);

    if (isMobile || isTablet) {
      mobileCameraInputRef.current?.click();
    } else {
      setIsDesktopWebcamModalOpen(true);
    }
  };
  const handleMobileImageCapture = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];

        // If the user is on a tablet in kiosk mode, they are using a card holder
        // Therefore, we have to crop the image
        if (isTablet && kioskMode) {
          const croppedImage = await cropCardHolderImage(file);

          if (captureSide === 'front') {
            setCaptureFront(croppedImage);
          } else {
            setCaptureBack(croppedImage);
          }
        } else {
          if (captureSide === 'front') {
            setCaptureFront(file);
          } else {
            setCaptureBack(file);
          }
        }
      }
    },
    [captureSide, isTablet, kioskMode]
  );
  const handleDesktopImageCapture = useCallback(
    (file: File) => {
      if (captureSide === 'front') {
        setCaptureFront(file);
      } else {
        setCaptureBack(file);
      }

      setIsDesktopWebcamModalOpen(false);
    },
    [captureSide]
  );
  const handleGoToNext = useCallback(async () => {
    try {
      setIsSubmitting(true);
      setError(null);

      if (!captureFront || !captureBack) {
        throw new Error('Did not capture both sides');
      }
      if (!dealership?.dealership?.name || !dealership.dealership.website) {
        throw new Error('Dealership not loaded');
      }

      const frontCaptureIsPng = captureFront.type.includes('png');
      const backCaptureIsPng = captureBack.type.includes('png');

      // Upload ID card pictures
      const [frontCaptureUpload, backCaptureUpload] = await Promise.all([
        await uploadFileAction(
          frontCaptureIsPng ? 'png' : 'jpg',
          frontCaptureIsPng ? 'image/png' : 'image/jpg',
          captureFront
        ),
        await uploadFileAction(
          backCaptureIsPng ? 'png' : 'jpg',
          backCaptureIsPng ? 'image/png' : 'image/jpg',
          captureBack
        )
      ]);

      // ##### Privacy Notice
      const privacyPolicyFormData: PrivacyPolicy = {
        submissionType: 'PRIVACY_POLICY',
        submissionData: {
          dateTime: format(new Date(), 'MM/dd/yyyy hh:mm a'),
          name: `${meData?.me?.user?.firstName ?? ''} ${meData?.me?.user?.lastName ?? ''}`.trim(),
          ipAddress: meData?.me?.ipAddress ?? 'Unknown IP',
          deviceId: window.navigator.userAgent,
          signature: ''
        }
      };
      const privacyPolicyPdf = await generatePdf(
        privacyDocument(t),
        {
          ...privacyPolicyFormData.submissionData,
          dealershipWebsite: dealership.dealership.website,
          dealershipName: dealership.dealership.name,
          date: format(new Date(), 'MM/dd/yyyy')
        },
        false,
        undefined,
        loadFontBytes
      );
      const privacyPolicyPdfBlob = new Blob([privacyPolicyPdf]);

      const privacyPolicyFile = await uploadFileAction(
        'pdf',
        'application/pdf',
        privacyPolicyPdfBlob
      );

      // ##### Vehicle test drive
      const testDriveData: TestDriveDisclosure = {
        submissionType: 'TEST_DRIVE_DISCLOSURE',
        submissionData: {
          dateTime: format(new Date(), 'MM/dd/yyyy hh:mm a'),
          name: `${meData?.me?.user?.firstName ?? ''} ${meData?.me?.user?.lastName ?? ''}`.trim(),
          ipAddress: meData?.me?.ipAddress ?? 'Unknown IP',
          deviceId: window.navigator.userAgent
        }
      };

      const testDrivePdf = await generatePdf(
        vehicleDiscolureTemplate(t),
        testDriveData.submissionData,
        false
      );

      const testDrivePdfBlob = new Blob([testDrivePdf]);

      const testDrivePdfUpload = await uploadFileAction(
        'pdf',
        'application/pdf',
        testDrivePdfBlob
      );

      // ##### DMV Document
      const dmvDocFormData: DmvDocument = {
        submissionType: 'DMV_DOCUMENTS',
        submissionData: {
          front: URL.createObjectURL(captureFront), // This is used to render the image on the PDF
          back: URL.createObjectURL(captureBack) // This is used to render the image on the PDF
        }
      };

      // Generate identity verification PDF, No text
      const dmvPdf = await generatePdf(
        dmvDocument,
        dmvDocFormData.submissionData,
        false
      );
      const dmvPdfBlob = new Blob([dmvPdf]);

      // In order not to save the file blob to th database, we merely save a file reference
      dmvDocFormData.submissionData.front = `tdcfile://${frontCaptureUpload.fileId}`;
      dmvDocFormData.submissionData.back = `tdcfile://${backCaptureUpload.fileId}`;

      // Upload identity verification PDF
      const dmvPdfUpload = await uploadFileAction(
        'pdf',
        'application/pdf',
        dmvPdfBlob
      );

      const submitStepResp = await gqlMutationClient()({
        onboardingSubmitStep1: [
          {
            transactionId: transactionId,
            step1: {
              captureFrontFileId:
                frontCaptureUpload.fileId ?? 'no-front-file-id',
              captureBackFileId: backCaptureUpload.fileId ?? 'no-back-file-id',
              dmvForm: JSON.stringify(dmvDocFormData),
              dmvPdfFileId: dmvPdfUpload.fileId ?? 'no-dmv-file-id',

              // Test drive form
              testDriveForm: JSON.stringify(testDriveData),
              testDriveFileId:
                testDrivePdfUpload.fileId ?? 'no-test-drive-file-id',

              // Privacy Policy
              privacyNoticeFileId:
                privacyPolicyFile.fileId ?? 'no-privacy-file-id',
              privacyNoticeForm: JSON.stringify(privacyPolicyFormData),

              contactConsent: contactConsent,
              testDriveDisclosureAgreed: testDriveDisclosureAgreed
            }
          },
          {
            __typename: true,
            '...on GraphQLError': {
              message: true
            },
            '...on MutationOnboardingSubmitStep1Success': {
              data: {
                status: true
              }
            }
          }
        ]
      });

      if (
        !submitStepResp.onboardingSubmitStep1 ||
        submitStepResp.onboardingSubmitStep1.__typename === 'GraphQLError'
      ) {
        console.error(
          submitStepResp.onboardingSubmitStep1?.message ?? 'Unexpected error'
        );
        setError(
          submitStepResp.onboardingSubmitStep1?.message ?? 'Unexpected error'
        );
      } else {
        // Reset queries to ensure we have fresh data
        await queryClient.refetchQueries();

        // Next step up!
        navigate('/onboarding/:dealershipSlug/:transactionId/step2', {
          params: { transactionId, dealershipSlug }
        });
      }

      // I can't really seem to find other solutions. Maybe the linter is too strict.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error(error);
      setError(`${error}`);
    } finally {
      setIsSubmitting(false);
    }
  }, [
    captureFront,
    captureBack,
    dealership?.dealership?.name,
    dealership?.dealership?.website,
    meData?.me?.user?.firstName,
    meData?.me?.user?.lastName,
    meData?.me?.ipAddress,
    t,
    transactionId,
    contactConsent,
    testDriveDisclosureAgreed,
    navigate,
    dealershipSlug
  ]);

  const canContinue =
    Boolean(captureFront) &&
    Boolean(captureBack) &&
    testDriveDisclosureAgreed &&
    contactConsent;

  return (
    <div className="flex flex-col h-dvh">
      <Header
        title={t('Your Transaction Information')}
        totalSteps={2}
        currentStep={1}
        leftElement={
          <button
            className=" text-primary-brand"
            onClick={() => void logoutAction()}
            disabled={isSubmitting}
          >
            {t('Cancel')}
          </button>
        }
      />

      {/**
       * This input opens the camera on mobile devices
       */}
      <input
        ref={mobileCameraInputRef}
        accept="image/*"
        type="file"
        capture="environment"
        className="hidden"
        onChange={(e) => void handleMobileImageCapture(e)}
      />

      {/**
       * This is the webcam modal for desktop
       */}
      <DesktopWebcamModal
        title={captureSide === 'front' ? t('Capture Front') : t('Capture Back')}
        isOpen={isDesktopWebcamModalOpen}
        orientation="LANDSCAPE"
        onClose={() => {
          setIsDesktopWebcamModalOpen(false);
        }}
        onDone={handleDesktopImageCapture}
      />

      <Modal
        isOpen={showCaptureIdAlert}
        onClose={() => {
          setShowCaptureIdAlert(false);
        }}
        className="mx-4"
      >
        <div className="flex flex-col p-8 pb-6 space-y-2 items-center border-b">
          <h2>{t('Capture ID-Card')}</h2>

          <p>
            {t(
              'Please place your ID on a flat and dark surface, capture the image without flash, and make sure the entire ID card is in the image (do not crop the edges).'
            )}
          </p>
        </div>

        <Button
          dataTestId="onboarding-step1-capture-continue"
          variant="TEXT_ONLY"
          onClick={handleCaptureContinue}
          className="w-full text-center p-4 text-lg"
        >
          {t('Continue')}
        </Button>
      </Modal>

      <div className="flex flex-col items-center overflow-y-scroll px-4">
        <div className="flex flex-col w-full max-w-screen-md py-20 md:py-30 lg:py-40 space-y-12 justify-between">
          <div className="space-y-12">
            <div className="space-y-5">
              {error && (
                <Banner variant="ERROR">
                  <div className="flex flex-col">
                    <p>
                      {t(
                        'An error happened. Please try again. If it continues to happen, please contact support.'
                      )}
                    </p>
                    <p className="font-bold">{error}</p>
                  </div>
                </Banner>
              )}

              <h1 className="text-heading-2">{t('Drivers License Capture')}</h1>

              <div className="space-y-3">
                <Banner variant="PRIVACY">
                  <p>
                    <Trans t={t}>
                      Prevent ID fraud and,{' '}
                      <span className="italic font-bold">if applicable</span>,
                      for Vehicle Finance and Motor Vehicle Registration.
                    </Trans>
                  </p>
                </Banner>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
              {/**
               * The user took an image of the front or there's an existing ID card
               */}
              {captureFront && (
                <div className="flex relative h-52">
                  <img
                    src={URL.createObjectURL(captureFront)}
                    className="size-full object-contain"
                  />
                </div>
              )}

              {/**
               * The user hasn't taken an image of the front and there's no existing ID card
               */}
              {!captureFront && (
                <div
                  data-test-id="onboarding-step1-capture-front"
                  className="flex flex-col space-y-4 w-full cursor-pointer h-52"
                  onClick={() => {
                    handleCapture('front');
                  }}
                >
                  <h3>{t('Capture Front')}</h3>

                  <CaptureIcon className="icon-tertiary" />
                </div>
              )}

              {/**
               * The user took an image of the back or there's an existing ID card
               */}
              {captureBack && (
                <div className="flex relative h-52">
                  <img
                    src={
                      URL.createObjectURL(captureBack) // We can force unwrap because if there's no exising ID card, there's a capture
                    }
                    className="size-full object-contain"
                  />
                </div>
              )}

              {/**
               * The user hasn't taken an image of the back and there's no existing ID card
               */}
              {!captureBack && (
                <div
                  data-test-id="onboarding-step1-capture-back"
                  className="flex flex-col space-y-4 w-full cursor-pointer h-52"
                  onClick={() => {
                    handleCapture('back');
                  }}
                >
                  <h3>{t('Capture Back')}</h3>

                  <CaptureIcon className="icon-tertiary" />
                </div>
              )}
            </div>

            <div className="space-y-5">
              <h1 className="text-heading-2">{t('Dealer Data Policy')}</h1>

              <div className="space-y-3">
                <Banner variant="PRIVACY">
                  <p>
                    <Trans t={t}>
                      <span className="font-bold">Privacy Commitment:</span>{' '}
                      Your information is secure and confidential, used only to
                      fulfill your request in line with our{' '}
                      <a
                        className={classNames(
                          'text-primary-brand cursor-pointer'
                        )}
                        href={t('privacy-notice', {
                          backendUrl: config.rawBackendUrl,
                          dealershipSlug: dealershipSlug,
                          lang: i18n.language,

                          ns: 'files',
                          defaultValue:
                            '{{backendUrl}}/document/{{dealershipSlug}}/{{lang}}/privacy-notice.pdf'
                        })}
                        target="_blank"
                      >
                        Privacy Notice
                      </a>{' '}
                      (click to view) which was emailed to you upon account
                      creation.
                    </Trans>
                  </p>
                </Banner>
              </div>
            </div>

            <div className="space-y-5">
              <h1 className="text-heading-2">{t('Contact Consent')}</h1>

              <div
                className="flex space-x-3 cursor-pointer"
                onClick={() => {
                  setContactConsent((p) => !p);
                }}
              >
                <Checkbox
                  onChange={() => {
                    setContactConsent((p) => !p);
                  }}
                  value={contactConsent}
                  dataTestId="onboarding-step1-contact-consent-checkbox"
                />
                <p>
                  <Trans t={t}>
                    Having provided my phone number, I consent to receive texts
                    and emails from this dealership.{' '}
                    <span className="font-bold italic">
                      I can opt out anytime
                    </span>
                    . My consent isn't required for purchase, and I accept any
                    messaging and any data charges that may apply by my service
                    provider.
                  </Trans>
                </p>
              </div>
            </div>

            <div className="space-y-5">
              <h1 className="text-heading-2">{t('Test Drive Disclosure')}</h1>

              <div className="space-y-3">
                <p>{t('(Applicable Only If Going on the Test Drive)')}</p>
                <p>
                  {t(
                    'In consideration of the Dealership permitting me to test drive their vehicle, I agree that:'
                  )}
                </p>
                <p>
                  {t(
                    // use ' instead of ’ to support the translation system.
                    // Use the translation system to replace the ' with ’
                    "I confirm that I have a valid driver's license and current collision and liability insurance covering the vehicle provided by the dealership. If the vehicle is damaged while in my possession, I will immediately notify the dealership and cover the repair costs, as well as any expenses incurred for the vehicle's return."
                  )}
                </p>
                <div
                  className="flex space-x-3 cursor-pointer"
                  onClick={() => {
                    setTestDriveDisclosureAgreed((p) => !p);
                  }}
                >
                  <Checkbox
                    onChange={() => {
                      setTestDriveDisclosureAgreed((p) => !p);
                    }}
                    value={testDriveDisclosureAgreed}
                    dataTestId="onboarding-step1-test-driver-disclosure-checkbox"
                  />
                  <p>
                    <Trans t={t}>
                      I understand that by checking this box, I am indicating my
                      full and voluntary agreement to the above{' '}
                      <a
                        className={classNames(
                          'text-primary-brand cursor-pointer'
                        )}
                        href={t('prequal-terms-and-conditions', {
                          ns: 'files',
                          defaultValue:
                            'https://files.mytdc.net/prequal-terms-and-conditions.pdf'
                        })}
                        target="_blank"
                      >
                        terms and conditions
                      </a>
                      , and to the{' '}
                      <a
                        className={classNames(
                          'text-primary-brand cursor-pointer'
                        )}
                        href={t(`test-drive-disclosure`, {
                          ns: 'files',
                          defaultValue: `/docs/test-drive-disclosure.pdf`
                        })}
                        target="_blank"
                      >
                        Vehicle Test Drive Disclosure
                      </a>{' '}
                      provided by the Dealership
                    </Trans>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end">
            <Button
              disabled={!canContinue}
              onClick={() => void handleGoToNext()}
              loading={isSubmitting}
              dataTestId="onboarding-step1-next-button"
            >
              {t('Next')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
