import { useQuery } from '@tanstack/react-query';
import { Fragment, useState } from 'react';
import logoutAction from '~/actions/logoutAction';
import Button from '~/components/Button';
import Spinner from '~/components/Spinner';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import DocumentIcon from '~/components/icons/DocumentIcon';
import meQuery from '~/queries/meQuery';
import transactionQuery from '~/queries/transactionQuery';
import { Link, useNavigate, useParams } from '~/router';

import { useTranslation } from 'react-i18next';
import PreQualForm from '~/pages/(authenticated)/_components/PreQualForm';
import ConsumerDashboardHeader from './_components/ConsumerDashboardHeader';

const PreQualPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { transactionId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/prequal'
  );
  const [loggingOut, setLoggingOut] = useState(false);
  const handleLogout = () => {
    setLoggingOut(true);
    logoutAction();
  };
  const { data: meData, isLoading: loadingMeData } = useQuery(meQuery());
  const { data: transactionData, isLoading: loadingTransactionData } = useQuery(
    transactionQuery(transactionId, dealershipSlug)
  );
  const handleSuccess = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: { transactionId, dealershipSlug }
    });
  };

  return (
    <div className="flex flex-col h-dvh">
      <ConsumerDashboardHeader
        leftElement={
          <div className="relative">
            <img src="/logo.png" className="h-4" aria-label="Logo" />
          </div>
        }
        centerElement={
          <div className="flex flex-row space-x-2">
            <div className="relative">
              <DocumentIcon className="size-4 md:size-5 icon-primary" />
            </div>

            <h2 className="hidden md:flex items-center">
              {t('Documents & Forms')}
            </h2>

            <h3 className="flex md:hidden items-center">
              {t('Documents & Forms')}
            </h3>
          </div>
        }
        rightElement={
          <Button
            variant="TEXT_ONLY"
            size="SMALL"
            onClick={() => {
              handleLogout();
            }}
            loading={loggingOut}
            className="!p-0"
          >
            <span className="text-secondary">{t('Logout')}</span>
          </Button>
        }
        showBorder
      />

      <div className="flex flex-col pt-2 space-y-6 w-full items-center overflow-y-scroll">
        <div className="w-full px-4 md:px-6">
          <Link
            to="/dashboard/:dealershipSlug/:transactionId"
            params={{ transactionId, dealershipSlug }}
            className="flex w-6"
          >
            <div className="relative">
              <ChevronRightIcon className="w-6 -scale-x-100 icon-tertiary" />
            </div>
          </Link>
        </div>

        {(loadingMeData || loadingTransactionData) && (
          <div className="flex w-full max-w-screen-md aspect-square justify-center items-center relative">
            <Spinner className="w-10 aspect-square" />
          </div>
        )}

        {!loadingMeData && !loadingTransactionData && (
          <Fragment>
            <div className="flex w-full px-6 justify-center">
              <div className="flex flex-col space-y-4 w-full max-w-screen-md">
                <div className="space-y-2">
                  <h2>{t('Pre-Qualification Application')}</h2>

                  <p className="text-secondary">
                    {t(
                      'Once this form has been filled out, you will not be able to edit it. Please read the information below:'
                    )}
                  </p>

                  <p className="text-secondary">
                    {t(
                      'This next step allows the dealer to view your credit score and accurately check what programs are available for your purchase.'
                    )}
                  </p>
                </div>

                <p className="text-secondary">
                  {t(
                    'Note: This action will not affect your credit score and is not considered a Hard Inquiry on your credit file.'
                  )}
                </p>
              </div>
            </div>

            <PreQualForm
              dealershipSlug={dealershipSlug}
              transactionId={transactionId}
              transactionData={transactionData}
              meData={meData}
              isSubmitting={isSubmitting}
              setIsSubmitting={setIsSubmitting}
              onSuccess={handleSuccess}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default PreQualPage;
