import { Button } from '@thedealersconcierge/components';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from '~/router';
import { Review } from '../../_components/Review';
import Scanner, { DocumentDetection } from '../../_components/Scanner';

export default function ScannerPage() {
  const [thePicture, setThePicture] = useState<null | string>(null);
  const { t } = useTranslation();
  const [pages, setPages] = useState<DocumentDetection[]>([]);
  const [page, setPage] = useState<'scanner' | 'review'>('scanner');

  const { temporaryToken, transactionId } = useParams(
    '/scanner/:transactionId/:temporaryToken'
  );

  const text = useMemo(
    () => ({
      hint: {
        OK: t('Capturing your document... Please do not move the camera.'),
        OK_SmallSize: t('The document is too small. Try moving closer.'),
        OK_BadAngles: t(
          'This is a bad camera angle. Hold the device straight over the document.'
        ),
        OK_BadAspectRatio: t(
          'Rotate the device sideways, so that the document fits better into the screen.'
        ),
        OK_OffCenter: t(
          'Try holding the device at the center of the document.'
        ),
        Error_NothingDetected: t(
          'Please hold the device over a document to start scanning.'
        ),
        Error_Brightness: t('It is too dark. Try turning on a light.'),
        Error_Noise: t('Please move the document to a clear surface.')
      }
    }),
    [t]
  );

  const onNew = (d: DocumentDetection) => {
    // Add the page to the end
    setPages((p) => [...p, d]);
    if (!d.cropped) {
      return;
    }
    const blob = new Blob([d.cropped], {
      type: `application/png`
    });
    const url = window.URL.createObjectURL(blob);
    setThePicture(url);
  };

  const MemoScanner = useMemo(
    () => <Scanner text={text} onNewPage={onNew} />,
    [text]
  );

  return (
    <div className="bg-gray-800 flex justify-center">
      <div className="h-dvh w-dvw max-w-lg bg-white">
        {page === 'scanner' && (
          <>
            <div className="">{MemoScanner}</div>
            <div className="absolute bottom-0 left-0 right-0 flex flex-row justify-between items-end z-50">
              <div className="p-6">
                {thePicture && (
                  <div className="aspect-square h-16 contain-strict">
                    <img src={thePicture} />
                  </div>
                )}
              </div>
              <div className="p-6">
                <Button
                  onClick={() => {
                    setPage('review');
                  }}
                  label={`(${pages.length}) Review`}
                />
              </div>
            </div>
          </>
        )}
        {page === 'review' && (
          <Review
            transactionId={transactionId}
            submissionToken={temporaryToken}
            pages={pages}
            onNavBack={() => {
              setPage('scanner');
            }}
          />
        )}
      </div>
    </div>
  );
}
