import classNames from 'classnames';
import { FC, ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';

/**
 *
 * @param {ReactElement} backComponent: Used in conjunction with totalSteps
 * @param {ReactElement} forwardComponent: Used in conjunction with totalSteps
 * @returns
 */
const Header: FC<{
  title?: string;
  totalSteps?: number;
  currentStep?: number;
  leftElement?: ReactElement;
  rightElement?: ReactElement;
  forwardComponent?: ReactElement;
  backComponent?: ReactElement;
  className?: string;
}> = ({
  title,
  totalSteps,
  currentStep,
  leftElement,
  rightElement,
  forwardComponent,
  backComponent,
  className
}) => {
  return (
    <div className={twMerge('relative z-20', className)}>
      <div className="relative flex w-full justify-center items-center py-3.5 border-b h-14">
        {leftElement && <div className="absolute left-4">{leftElement}</div>}

        {title && <h4 className="heading-04">{title}</h4>}

        {rightElement && <div className="absolute right-4">{rightElement}</div>}
      </div>

      {!!totalSteps && !!currentStep && (
        <div className="flex flex-row w-full absolute top-14 gap-3 justify-center bg-primary p-6">
          {backComponent && (
            <div className="absolute left-4 md:left-6 top-3">
              {backComponent}
            </div>
          )}

          {Array.from(Array(totalSteps)).map((_step, index) => {
            return (
              <div
                key={index}
                className={classNames('size-2.5 rounded-full', {
                  'bg-gray-300': index >= currentStep,
                  'bg-interactive-primary': index < currentStep
                })}
              />
            );
          })}

          {forwardComponent && (
            <div className="absolute right-6 top-3">{forwardComponent}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default Header;
