import { captureException } from '@sentry/react';
import { Button, TextInput } from '@thedealersconcierge/components';
import { useAtom } from 'jotai';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cognitoVerifyOtpAction } from '~/actions/auth/cognitoVerifyOtpAction';
import verifyOtpResponseAction from '~/actions/auth/verifyOtpResponseAction';
import Modal from '~/components/Modal';
import config from '~/config';
import { authStateAtom } from '~/state/auth';

const LoginModal: FC<{
  isOpen: boolean;
  email: string;
  onLoginSuccess: () => void | Promise<void>;
}> = ({ isOpen, email, onLoginSuccess }) => {
  const { t } = useTranslation();
  const [, setAuthState] = useAtom(authStateAtom);
  const [otp, setOtp] = useState('');
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [loginError, setLoginError] = useState<string | null>(null);

  const submitOtp = async () => {
    try {
      setIsLoggingIn(true);
      setLoginError(null);

      if (config.useCognito) {
        await cognitoVerifyOtpAction(otp);
      } else {
        const verifyOtpResponse = await verifyOtpResponseAction(email, otp);

        if (!verifyOtpResponse.jwt) {
          throw new Error('No JWT returned');
        }

        setAuthState({ jwt: verifyOtpResponse.jwt });
      }

      // We are awaiting it because after verifying the OTP, we are submitting the credit application
      await onLoginSuccess();
    } catch (error) {
      if (
        error instanceof Error &&
        error.message === 'Invalid code for email'
      ) {
        setLoginError('OTP is invalid');

        return;
      }

      captureException(error);
    } finally {
      setIsLoggingIn(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t('Verification')}
      className="p-6 mx-10"
      // We allow no control for the component
      onClose={() => {}}
    >
      <div className="flex flex-col items-center space-y-6">
        <div className="space-y-4">
          <p>
            {t(
              'Our records show that you already hold an account. We have sent a verification code to your phone and email.'
            )}
          </p>

          <TextInput
            value={otp}
            placeholder={t('Code')}
            label={t('Code')}
            required
            disabled={false}
            onChange={setOtp}
            errorMessage={loginError}
          />
        </div>

        <Button
          label="Submit"
          dataTestId="credit-create-submit-otp"
          onClick={() => {
            void submitOtp();
          }}
          disabled={otp.length !== 6}
          isLoading={isLoggingIn}
        />
      </div>
    </Modal>
  );
};

export default LoginModal;
