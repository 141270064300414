import { ReactFormApi } from '@tanstack/react-form';
import { ZodValidator } from '@tanstack/zod-form-adapter';
import {
  DateInput,
  PhoneNumberInput,
  SocialSecurityNumberInput,
  TextInput
} from '@thedealersconcierge/components';
import { inputBackgroundTypes } from '@thedealersconcierge/components/src/input/inputConfig';
import { phoneNumber } from '@thedealersconcierge/lib/codecs/validation/phonenumber';
import { socialSecurityNumber } from '@thedealersconcierge/lib/codecs/validation/socialSecurityNumber';
import { TFunction } from 'i18next';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import FormContainer from './FormContainer';

export type CreditApplicationBasicInformationFormValues = {
  firstName: string;
  middleName: string;
  lastName: string;
  dob: string;
  socialSecurityNumber: string;
  phoneNumber: string;
  email: string;
};

export const ValidCreditApplicationBasicInformationSchema = (t: TFunction) =>
  z.object({
    firstName: z.string().min(1, t('This field is required')),
    middleName: z.string(),
    lastName: z.string().min(1, t('This field is required')),
    dob: z
      .string()
      .datetime(t('This field is required')) // The date picker selects values as DateTime strings
      .or(z.string().date('This field is required')), // The API returns data as Date strings
    socialSecurityNumber: socialSecurityNumber(),
    phoneNumber: phoneNumber(),
    email: z.string().email()
  });

const CreditApplicationBasicInformationForm: FC<{
  form: ReactFormApi<CreditApplicationBasicInformationFormValues, ZodValidator>;
  isSubmitting: boolean;
  className?: string;
  environment: 'WEB' | 'IN_APP';
}> = ({ form, isSubmitting, className, environment }) => {
  const { t } = useTranslation();

  return (
    <FormContainer title={t('Basic Information')} className={className}>
      <Fragment>
        <form.Field
          name="firstName"
          validators={{
            onBlur: z.string().min(1, t('This field is required'))
          }}
        >
          {(field) => {
            return (
              <TextInput
                value={field.state.value}
                label={t('First Name')}
                placeholder={t('First Name')}
                required
                disabled={isSubmitting}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors.at(0)?.toString()}
                backgroundType={inputBackgroundTypes.LIGHT}
                className="col-span-2 desktop:col-span-3"
              />
            );
          }}
        </form.Field>

        <form.Field
          name="middleName"
          validators={{
            onBlur: z.string()
          }}
        >
          {(field) => {
            return (
              <TextInput
                value={field.state.value}
                label={t('Middle Name')}
                placeholder={t('Middle Name')}
                required={false}
                disabled={isSubmitting}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors.at(0)?.toString()}
                backgroundType={inputBackgroundTypes.LIGHT}
                className="col-span-2"
              />
            );
          }}
        </form.Field>

        <form.Field
          name="lastName"
          validators={{
            onBlur: z.string().min(1, t('This field is required'))
          }}
        >
          {(field) => {
            return (
              <TextInput
                value={field.state.value}
                label={t('Last Name')}
                placeholder={t('Last Name')}
                required
                disabled={isSubmitting}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors.at(0)?.toString()}
                backgroundType={inputBackgroundTypes.LIGHT}
                className="col-span-4 tablet:col-span-2 desktop:col-span-3"
              />
            );
          }}
        </form.Field>

        <form.Field
          name="dob"
          validators={{
            onBlur: z
              .string()
              .datetime(t('This field is required')) // The date picker selects values as DateTime strings
              .or(z.string().date('This field is required')), // The API returns data as Date strings
            // We do the onChange validation because the calendar pop-up stays open when a value is selected
            onChange: z
              .string()
              .datetime(t('This field is required')) // The date picker selects values as DateTime strings
              .or(z.string().date('This field is required')) // The API returns data as Date strings
          }}
        >
          {(field) => {
            return (
              <DateInput
                value={field.state.value}
                label={t('Date of Birth')}
                placeholder={t('Date of Birth')}
                required
                disabled={isSubmitting}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors.at(0)?.toString()}
                backgroundType={inputBackgroundTypes.LIGHT}
                className="col-span-4 tablet:col-span-3 desktop:col-span-4"
              />
            );
          }}
        </form.Field>

        <form.Field
          name="socialSecurityNumber"
          validators={{
            onBlur: socialSecurityNumber()
          }}
        >
          {(field) => {
            return (
              <SocialSecurityNumberInput
                value={field.state.value}
                label={t('Social Security Number/TIN')}
                placeholder={t('Social Security Number/TIN')}
                required
                disabled={isSubmitting}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors.at(0)?.toString()}
                backgroundType={inputBackgroundTypes.LIGHT}
                className="col-span-4 tablet:col-span-3 desktop:col-span-4"
              />
            );
          }}
        </form.Field>

        <form.Field
          name="phoneNumber"
          validators={{
            onBlur: phoneNumber()
          }}
        >
          {(field) => {
            return (
              <PhoneNumberInput
                value={field.state.value}
                label={t('Phone Number')}
                placeholder={t('Phone Number')}
                required
                disabled={isSubmitting || environment === 'IN_APP'}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors.at(0)?.toString()}
                backgroundType={inputBackgroundTypes.LIGHT}
                className="col-span-4 tablet:col-span-3 desktop:col-span-4"
              />
            );
          }}
        </form.Field>

        <form.Field
          name="email"
          validators={{
            onBlur: z.string().email()
          }}
        >
          {(field) => {
            return (
              <TextInput
                value={field.state.value}
                inputType="email"
                label={t('Email')}
                placeholder={t('Email')}
                required
                disabled={isSubmitting || environment === 'IN_APP'}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors.at(0)?.toString()}
                backgroundType={inputBackgroundTypes.LIGHT}
                className="col-span-4 tablet:col-span-3 desktop:col-span-4"
              />
            );
          }}
        </form.Field>
      </Fragment>
    </FormContainer>
  );
};

export default CreditApplicationBasicInformationForm;
