import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

/**
 * It might make sense to move this into the shared component library when it is used on more screens.
 */
const FormContainer: FC<{
  title: string;
  children: ReactNode;
  className?: string;
  dataTestId?: string;
}> = ({
  /**
   * Describing the content of the form.
   * Shown at the top of the form.
   */
  title,
  /**
   * Numerous form fields placed in the grid structure.
   */
  children,
  className,
  dataTestId
}) => {
  return (
    <form
      className={twMerge(
        classNames(
          'col-span-4 tablet:col-span-6 desktop:col-span-8 tablet:col-start-2 desktop:col-start-3', // Parent grid layout
          'grid grid-cols-subgrid gap-y-spacing-02 desktop:gap-y-spacing-04', // Grid layout
          'p-spacing-04 tablet:p-spacing-05 desktop:p-spacing-06 bg-secondary rounded-lg'
        ),
        className
      )}
      data-test-id={dataTestId}
    >
      <h3 className="heading-03 col-span-4 tablet:col-span-6 desktop:col-span-8">
        {title}
      </h3>

      {children}
    </form>
  );
};

export default FormContainer;
