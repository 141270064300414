import * as Sentry from '@sentry/react';
import { useForm } from '@tanstack/react-form';
import { useQuery } from '@tanstack/react-query';
import {
  Button,
  PhoneNumberInput,
  TextInput
} from '@thedealersconcierge/components';
import classNames from 'classnames';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-phone-number-input/style.css';
import { toast } from 'react-toastify';
import { cognitoRequestOtpAction } from '~/actions/auth/cognitoRequestOtpAction';
import { cognitoVerifyOtpAction } from '~/actions/auth/cognitoVerifyOtpAction';
import customerCreateTransactionAction from '~/actions/auth/customerCreateTransactionAction';
import customerCreateUnauthenticatedTransactionAction from '~/actions/auth/customerCreateUnauthenticatedTransactionAction';
import { hasExistingUserAction } from '~/actions/auth/hasExistingUser';
import requestOtpExistingUserDEVAction from '~/actions/auth/requestOtpExistingUserDEVAction';
import verifyOtpResponseAction from '~/actions/auth/verifyOtpResponseAction';
import Header from '~/components/Header';
import Modal from '~/components/Modal';
import Checkbox from '~/components/inputs/Checkbox';
import DropDown from '~/components/inputs/DropDown';
import config from '~/config';
import store from '~/lib/store';
import dealershipQuery from '~/queries/dealershipQuery';
import { resetMeQuery } from '~/queries/meQuery';
import { Link, useNavigate } from '~/router';
import { authStateAtom } from '~/state/auth';
import { kioskDealershipAtom } from '~/state/kiosk';
import { languageAtom } from '~/state/language';

export default function SignUp() {
  // When a user clicks the "continue" button, we create the transaction and then prompt them for OTPs
  const [creatingTransaction, setCreatingTransaction] = useState(false);
  const [sendingOtps, setSendingOtps] = useState(false);
  const [, setAuthState] = useAtom(authStateAtom);
  const { t } = useTranslation();
  const [
    isRegistrationCompletionModalOpen,
    setIsRegistrationCompletionModalOpen
  ] = useState(false);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [code, setCode] = useState('');
  const [loggingIn, setLoggingIn] = useState(false);

  // Error when creating transaction
  const [registrationError, setRegistrationError] = useState<string | null>(
    null
  );

  // Error when sending OTPs
  const [otpError, setOtpError] = useState<string | null>(null);

  // Error when logging in/verifying OTPs
  const [loginError, setLoginError] = useState<string | null>(null);

  // If a user is trying to login already has an account, we ask them to login
  // and create the user after they have logged in
  const [
    createTransactionForExistingUser,
    setCreateTransactionForExistingUser
  ] = useState(false);

  const navigate = useNavigate();
  const kioskMode = useAtomValue(kioskDealershipAtom);
  const { data: dealership } = useQuery(
    dealershipQuery({ slug: kioskMode?.dealershipSlug })
  );
  const salesPersons = dealership?.dealership?.salesPeople ?? [];

  const form = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      salesPersonId: '',
      zipCode: '',
      acceptedConatctAcknowledgement: false
    },
    onSubmit: async (formData) => {
      try {
        const data = formData.value;
        setCreatingTransaction(true);
        setRegistrationError(null);

        if (!dealership?.dealership?.id) {
          throw new Error('No dealership selected');
        }

        // Email exists, go into the login flow, an note that we will create a new transactions
        // after login
        const hasExistingUser = await hasExistingUserAction(data.email);

        if (hasExistingUser) {
          setCreateTransactionForExistingUser(true);

          await handleContinue();

          return;
        }

        // Sign up the user
        try {
          await customerCreateUnauthenticatedTransactionAction(
            data.firstName,
            data.lastName,
            data.email,
            data.phoneNumber,
            dealership.dealership.id,
            data.acceptedConatctAcknowledgement,
            store.instance.get(languageAtom),
            data.salesPersonId === '' ? null : data.salesPersonId,
            data.zipCode === '' ? null : data.zipCode
          );
        } catch (error) {
          /**
           * TODO: Handle this before submitting the form by validitaing the input onBlur.
           * This will require us to update the tanstack-form package.
           */
          if (
            error instanceof Error &&
            error.message.includes('is not a valid phone number')
          ) {
            setRegistrationError('The provided phone number is invalid');

            return;
          }

          throw error;
        }

        setIsRegistrationCompletionModalOpen(true);
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
        toast.error('Could not complete registration');
      } finally {
        setCreatingTransaction(false);
      }
    }
  });

  // The submit OTP callback should only be called if there is an existing
  // user for the transaction.
  const submitOtp = useCallback(
    async (email: string, otp: string) => {
      try {
        setLoggingIn(true);
        setLoginError(null);

        if (!kioskMode) {
          throw new Error('This page requires kiosk mode');
        }

        try {
          if (config.useCognito) {
            await cognitoVerifyOtpAction(otp);
          } else {
            const verifyOtpResponse = await verifyOtpResponseAction(email, otp);

            if (!verifyOtpResponse.jwt) {
              throw new Error('No JWT returned');
            }

            setAuthState({ jwt: verifyOtpResponse.jwt });
          }
        } catch (error) {
          if (
            error instanceof Error &&
            error.message === 'Invalid code for email'
          ) {
            setLoginError('OTP is invalid');

            return;
          }

          throw error;
        }

        await resetMeQuery();

        // Check if we need to create a transaction for an existing user
        if (createTransactionForExistingUser) {
          const salesPersonId = form.getFieldValue('salesPersonId');
          const firstName = form.getFieldValue('firstName');
          const lastName = form.getFieldValue('lastName');

          const t = await customerCreateTransactionAction({
            dealershipSlug: kioskMode.dealershipSlug,
            language: store.instance.get(languageAtom),
            firstName: firstName === '' ? undefined : firstName,
            lastName: lastName === '' ? undefined : lastName,
            salesPersonId: salesPersonId === '' ? undefined : salesPersonId
          });
          // Navigating to dashboard will redirect the user correctly
          navigate('/dashboard', {
            state: {
              redirectTo: t.id
            }
          });
        } else {
          // Navigating to dashboard will redirect the user correctly
          navigate('/dashboard', {
            state: {
              doRedirect: true
            }
          });
        }
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
        toast.error('Could not login');
      } finally {
        setLoggingIn(false);
      }
    },
    [createTransactionForExistingUser, kioskMode]
  );

  const handleContinue = async () => {
    try {
      setSendingOtps(true);
      setOtpError(null);

      const email = form.getFieldValue('email');

      if (config.useCognito) {
        await cognitoRequestOtpAction(email);
      } else {
        await requestOtpExistingUserDEVAction(email);
      }

      // We arrived here, meaning an OTP has been sent to the user
      setIsRegistrationCompletionModalOpen(false);

      // The flow continues here
      setIsOtpModalOpen(true);
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      toast.error('Could not send OTPs');
    } finally {
      setSendingOtps(false);
    }
  };

  const handleCloseOtpModal = () => {
    setIsOtpModalOpen(false);
    setIsRegistrationCompletionModalOpen(true);
  };

  const handleCloseRegistrationCompletionModal = () => {
    navigate('/');
  };

  return (
    <div className="flex flex-col h-dvh">
      <Header
        title={t('New User')}
        leftElement={
          <Link className="text-primary-brand" to="/">
            {t('Back')}
          </Link>
        }
      />

      <Modal
        isOpen={isRegistrationCompletionModalOpen}
        onClose={handleCloseRegistrationCompletionModal}
      >
        <div className="p-8 space-y-10">
          <div className="space-y-4">
            <h2>{t('Registration Was Successful!')}</h2>

            <p>
              {t(
                'Would you like to continue? (Continue if you are ready to go on a test drive or would like to pre-qualify)'
              )}
            </p>
          </div>

          {otpError && (
            <div className="flex bg-negative-primary w-full py-1 rounded-md text-primary-inverse items-center justify-center">
              <p>{otpError}</p>
            </div>
          )}

          <div className="flex flex-row justify-end space-x-2">
            <Button
              label={t('Finish and Exit')}
              variant="SECONDARY"
              onClick={handleCloseRegistrationCompletionModal}
              dataTestId="kiosk-finish-and-exit"
            />

            <Button
              label={t('Continue')}
              isLoading={sendingOtps}
              onClick={() => void handleContinue()}
              dataTestId="kiosk-create-continue"
            />
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isOtpModalOpen}
        title={t('Verification')}
        className="p-6 mx-10"
        onClose={handleCloseOtpModal}
      >
        <div className="flex flex-col items-center space-y-6">
          <div className="space-y-4">
            {createTransactionForExistingUser && (
              <p>
                {t(
                  'To login, enter the code we have sent to your email and phone.'
                )}
              </p>
            )}
            {!createTransactionForExistingUser && (
              <p>
                {t(
                  'Our records show that you already hold an account. We have sent a verification code to your phone and email.'
                )}
              </p>
            )}

            <div className="space-y-2">
              <TextInput
                dataTestId="kiosk-create-enter-otp"
                value={code}
                placeholder={t('Code')}
                label={t('Code')}
                required={false}
                disabled={false}
                onChange={(e) => {
                  setCode(e);
                }}
                backgroundType="LIGHT"
              />
            </div>
          </div>

          {loginError && (
            <div className="flex bg-negative-primary w-full py-1 rounded-md text-primary-inverse  items-center justify-center">
              <p>{loginError}</p>
            </div>
          )}

          <Button
            label={t('Submit')}
            dataTestId="kiosk-create-submit-otp"
            onClick={() => void submitOtp(form.getFieldValue('email'), code)}
            disabled={code.length !== 6}
            isLoading={loggingIn}
          />
        </div>
      </Modal>

      <div className="flex flex-col items-center overflow-y-scroll px-4">
        <div
          className={classNames(
            'flex flex-col w-full max-w-2xl space-y-12 p-10',
            'sm:p-12',
            'md:p-14',
            'lg:p-16'
          )}
        >
          <div className="space-y-5">
            <h1>{t('Sign Up')}</h1>

            <p>{t('Please input your information')}</p>
          </div>

          <form
            className="overflow-y-scroll space-y-6"
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              void form.handleSubmit();
            }}
          >
            <div className="flex flex-col space-y-6">
              <div className="grid grid-cols-2 gap-6">
                <form.Field name="firstName">
                  {(field) => {
                    return (
                      <TextInput
                        dataTestId="kiosk-create-first-name"
                        name={field.name}
                        value={field.state.value}
                        label={t('First Name')}
                        placeholder={t('First Name')}
                        required
                        disabled={false}
                        errorMessage={
                          field.state.meta.errors.length > 0
                            ? field.state.meta.errors.join(', ')
                            : undefined
                        }
                        onChange={(e) => {
                          field.handleChange(e);
                        }}
                        backgroundType="LIGHT"
                      />
                    );
                  }}
                </form.Field>

                <form.Field name="lastName">
                  {(field) => {
                    return (
                      <TextInput
                        dataTestId="kiosk-create-last-name"
                        name={field.name}
                        value={field.state.value}
                        label={t('Last Name')}
                        placeholder={t('Last Name')}
                        required
                        disabled={false}
                        errorMessage={
                          field.state.meta.errors.length > 0
                            ? field.state.meta.errors.join(', ')
                            : undefined
                        }
                        onChange={(e) => {
                          field.handleChange(e);
                        }}
                        backgroundType="LIGHT"
                      />
                    );
                  }}
                </form.Field>
              </div>

              <form.Field name="email">
                {(field) => {
                  return (
                    <TextInput
                      dataTestId="kiosk-create-email"
                      name={field.name}
                      value={field.state.value}
                      inputType="email"
                      label={t('Email')}
                      placeholder={t('Email')}
                      required
                      disabled={false}
                      errorMessage={
                        field.state.meta.errors.length > 0
                          ? field.state.meta.errors.join(', ')
                          : undefined
                      }
                      onChange={(e) => {
                        field.handleChange(e.toLocaleLowerCase().trim());
                      }}
                      backgroundType="LIGHT"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="phoneNumber">
                {(field) => {
                  return (
                    <PhoneNumberInput
                      dataTestId="kiosk-create-phone-number"
                      name={field.name}
                      value={field.state.value}
                      placeholder={t('Phone Number')}
                      label={t('Phone Number')}
                      onChange={(value) => {
                        field.handleChange(value);
                      }}
                      errorMessage={
                        field.state.meta.errors.length > 0
                          ? field.state.meta.errors.join(', ')
                          : undefined
                      }
                      required
                      disabled={false}
                      backgroundType="LIGHT"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="zipCode">
                {(field) => {
                  return (
                    <TextInput
                      name={field.name}
                      value={field.state.value}
                      inputType="text"
                      label={t('Zip Code')}
                      placeholder={t('Zip Code')}
                      required={false}
                      disabled={false}
                      errorMessage={
                        field.state.meta.errors.length > 0
                          ? field.state.meta.errors.join(', ')
                          : undefined
                      }
                      onChange={(e) => {
                        field.handleChange(e.toLocaleLowerCase().trim());
                      }}
                      backgroundType="LIGHT"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="salesPersonId">
                {(field) => {
                  return (
                    <DropDown
                      dataTestId="kiosk-create-sales-person"
                      fieldName={field.name}
                      value={field.state.value}
                      options={salesPersons.map((s) => ({
                        value: s.value ?? 'no-value',
                        label: s.label ?? 'no-label'
                      }))}
                      labelText={t('Sales Person')}
                      placeholder={t('Sales Person')}
                      subtitleText={t('Sales Person')}
                      onChange={(e) => {
                        field.handleChange(e.target.value);
                      }}
                    />
                  );
                }}
              </form.Field>
            </div>

            {registrationError && (
              <div className="flex rounded-md bg-negative-primary text-primary-inverse w-full py-1 items-center justify-center">
                <p>{registrationError}</p>
              </div>
            )}

            <div className="space-y-4">
              <p>
                {t(
                  'To ensure a Secure, Fast, and Seamless entry into the TDC portal, you will be prompted to enter a one-time passcode (OTP) each time you log in.'
                )}
              </p>

              <form.Field name="acceptedConatctAcknowledgement">
                {(field) => {
                  return (
                    <div
                      className="flex flex-row space-x-6 items-start cursor-pointer"
                      onClick={() => {
                        field.handleChange(!field.state.value);
                      }}
                    >
                      <Checkbox
                        dataTestId="kiosk-create-consent"
                        inputId={field.name}
                        value={field.state.value}
                        onChange={() => {
                          field.handleChange(!field.state.value);
                        }}
                        inputClassName="mt-1"
                      />
                      <p>
                        {t(
                          'By entering my mobile phone number above and checking this box, I consent to receiving text messages and emails from TDC. I acknowledge my responsibility to cover any text messaging and data charges imposed by my mobile service provider, if applicable.'
                        )}
                      </p>
                    </div>
                  );
                }}
              </form.Field>
            </div>

            <form.Subscribe>
              {(form) => {
                const canContinue =
                  !!form.values.acceptedConatctAcknowledgement &&
                  !!form.values.email &&
                  !!form.values.phoneNumber &&
                  !!form.values.firstName &&
                  !!form.values.lastName;

                return (
                  <div className="w-full flex justify-end space-x-4">
                    <Button
                      label={t('Register')}
                      type="submit"
                      disabled={!canContinue}
                      isLoading={creatingTransaction}
                      dataTestId="kiosk-create-submit"
                    />
                  </div>
                );
              }}
            </form.Subscribe>
          </form>
        </div>
      </div>
    </div>
  );
}
