import { gqlMutationClient } from '~/lib/backend';

export const hasExistingUserAction = async (email: string) => {
  const resp = await gqlMutationClient()({
    userExists: [
      {
        email
      },
      {
        __typename: true,
        '...on GraphQLError': {
          message: true
        },
        '...on MutationUserExistsSuccess': {
          data: true
        }
      }
    ]
  });

  if (resp.userExists?.__typename === 'MutationUserExistsSuccess') {
    return resp.userExists.data;
  }

  throw new Error(
    resp.userExists?.__typename === 'GraphQLError'
      ? resp.userExists.message
      : 'Failed to check if user exists'
  );
};
