import { FC, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import InputBase from '..';
import { InputBackgroundType } from '../inputConfig';

const PhoneNumberInput: FC<{
  /**
   * dataTestId for E2E testing
   */
  dataTestId?: string;
  value: string;
  label: string;
  name?: string;
  assistiveMessage?: string;
  errorMessage?: string;
  placeholder: string;
  onChange: (value: string) => void;
  onBlur?: (() => void) | undefined;
  required: boolean;
  disabled: boolean;
  className?: string;
  backgroundType?: InputBackgroundType;
}> = ({
  dataTestId,
  value,
  label,
  name,
  assistiveMessage,
  errorMessage,
  placeholder,
  onChange,
  onBlur,
  required,
  disabled,
  className,
  backgroundType = 'DARK'
}) => {
  const [isTyping, setIsTyping] = useState(false);

  return (
    <InputBase
      inputComponent={
        <PhoneInput
          name={name}
          data-test-id={dataTestId}
          value={value}
          placeholder={placeholder}
          defaultCountry="US"
          onChange={(value) => {
            onChange(value ?? '');
          }}
          onFocus={() => {
            setIsTyping(true);
          }}
          onBlur={() => {
            setIsTyping(false);
            onBlur?.();
          }}
          disabled={disabled}
          className="px-spacing-02 py-[6px] space-x-spacing-02 w-full *:m-0 *:bg-transparent *:body-01 *:text-primary *:placeholder:text-tertiary *:disabled:text-tertiary *:outline-none"
        />
      }
      label={label}
      assistiveMessage={assistiveMessage}
      required={required}
      disabled={disabled}
      isBeingUpdated={isTyping}
      errorMessage={errorMessage}
      backgroundType={backgroundType}
      className={className}
    />
  );
};

export default PhoneNumberInput;
