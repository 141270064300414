/**
 * Exporting the config from a separate file to keep React fast-refresh working.
 */

export const inputBackgroundTypes = {
  LIGHT: 'LIGHT',
  DARK: 'DARK'
} as const;

export type InputBackgroundType =
  (typeof inputBackgroundTypes)[keyof typeof inputBackgroundTypes];
