import { captureException } from '@sentry/react';
import { useForm } from '@tanstack/react-form';
import { useQuery } from '@tanstack/react-query';
import { ZodValidator, zodValidator } from '@tanstack/zod-form-adapter';
import { Button, ProgressBar, Spinner } from '@thedealersconcierge/components';
import { isoStringToDateWithoutTimeZone } from '@thedealersconcierge/lib/utils/dates';
import { formatSSN } from '@thedealersconcierge/lib/utils/strings';
import classNames from 'classnames';
import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import createAddressAction from '~/actions/addresses/createAddressAction';
import updateAddressAction from '~/actions/addresses/updateAddressAction';
import AddressForm, {
  AddressFormValues,
  ValidAddressSchema
} from '~/components/forms/AddressForm';
import CreditApplicationBasicInformationForm, {
  CreditApplicationBasicInformationFormValues,
  ValidCreditApplicationBasicInformationSchema
} from '~/components/forms/CreditApplicationBasicInformationForm';
import Header from '~/components/Header';
import { gqlMutationClient } from '~/lib/backend';
import { stringToVehicleRegistrationAddressAnswers } from '~/lib/enumMap';
import customerQuery, {
  CustomerQueryType,
  resetCustomerQuery
} from '~/queries/customerQuery';
import meQuery from '~/queries/meQuery';
import { useNavigate, useParams } from '~/router';

const Forms: FC<{
  transactionId: string;
  userId: string;
  dealershipSlug: string;
  existingData?: CustomerQueryType | undefined;
}> = ({ transactionId, userId, dealershipSlug, existingData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  // This is meant to track a newly created previous address.
  // This is necessary if the basic information form is invalid and we would not proceed after creation.
  // We have to make sure, we update the newly created one instead of creating a new one.
  const [createdPreviousAddressId, setCreatedPreviousAddressId] = useState<
    string | null
  >(null);
  const existingCustomerData = existingData?.customer;
  // We will always have an existing current address from the Prequal
  const existingCurrentAddress =
    existingCustomerData?.residentialAddresses?.edges?.find(
      (e) => e.node?.timelinePosition === 0
    )?.node;
  // We might have an existing previous address if the user cancel the credit application flow and then resumes it or by navigating between the steps.
  const exsitingPreviousAddress =
    existingCustomerData?.residentialAddresses?.edges?.find(
      (e) => e.node?.timelinePosition === 1
    )?.node;
  const basicInformationForm = useForm<
    CreditApplicationBasicInformationFormValues,
    ZodValidator
  >({
    defaultValues: {
      firstName: existingCustomerData?.firstName ?? '',
      middleName: existingCustomerData?.middleName ?? '',
      lastName: existingCustomerData?.lastName ?? '',
      dob: existingCustomerData?.birthdate ?? '',
      socialSecurityNumber: existingCustomerData?.socialSecurityNumber
        ? formatSSN(existingCustomerData.socialSecurityNumber)
        : '',
      phoneNumber: existingCustomerData?.user?.phoneNumber ?? '',
      email: existingCustomerData?.user?.email ?? ''
    },
    validators: {
      onSubmit: ValidCreditApplicationBasicInformationSchema(t)
    },
    validatorAdapter: zodValidator(),
    onSubmit: async ({ value }) => {
      const resp = await gqlMutationClient()({
        updateCustomer: [
          {
            transactionId,
            customer: {
              firstName: value.firstName,
              middleName: value.middleName,
              lastName: value.lastName,
              dob: isoStringToDateWithoutTimeZone(value.dob),
              socialSecurityNumber: value.socialSecurityNumber
            }
          },
          {
            __typename: true,
            '...on GraphQLError': {
              message: true
            },
            '...on MutationUpdateCustomerSuccess': {
              data: {
                status: true
              }
            }
          }
        ]
      });

      if (resp.updateCustomer?.__typename === 'GraphQLError') {
        throw new Error(resp.updateCustomer.message);
      }
    }
  });
  const currentAddressForm = useForm<AddressFormValues, ZodValidator>({
    defaultValues: {
      vehicleRegistrationAddressAnswer:
        existingCustomerData?.vehicleRegistrationAddressAnswer ?? '',
      street: existingCurrentAddress?.street ?? '',
      apartmentDetails: existingCurrentAddress?.apartmentDetails ?? '',
      city: existingCurrentAddress?.city ?? '',
      state: existingCurrentAddress?.state ?? '',
      zipCode: existingCurrentAddress?.zipCode ?? '',
      housingStatus: existingCurrentAddress?.housingStatus ?? '',
      monthlyPayment: existingCurrentAddress?.monthlyPayment?.toString() ?? '',
      durationYears: existingCurrentAddress?.durationYears?.toString() ?? '',
      durationMonths: existingCurrentAddress?.durationMonths?.toString() ?? ''
    },
    validators: {
      onSubmit: ValidAddressSchema(t, 'CURRENT', 'IN_APP')
    },
    validatorAdapter: zodValidator(),
    onSubmit: async ({ value }) => {
      // We will always have an existing current address from the Prequal
      // Therefore, we are updating the existing one instead of creating a new one
      await updateAddressAction(
        existingCurrentAddress?.id ?? 'no-current-address-id',
        {
          street: value.street,
          apartmentDetails: value.apartmentDetails,
          city: value.city,
          state: value.state,
          zipCode: value.zipCode,
          housingStatus: value.housingStatus,
          monthlyPayment: value.monthlyPayment
            ? parseFloat(value.monthlyPayment)
            : undefined,
          durationYears: value.durationYears
            ? parseInt(value.durationYears)
            : undefined,
          durationMonths: value.durationMonths
            ? parseInt(value.durationMonths)
            : undefined
        }
      );

      // Save the vehicle registration address answer
      const resp = await gqlMutationClient()({
        updateCustomer: [
          {
            transactionId,
            customer: {
              vehicleRegistrationAddressAnswer:
                stringToVehicleRegistrationAddressAnswers(
                  value.vehicleRegistrationAddressAnswer
                )
            }
          },
          {
            __typename: true,
            '...on GraphQLError': {
              message: true
            },
            '...on MutationUpdateCustomerSuccess': {
              data: {
                status: true
              }
            }
          }
        ]
      });

      if (resp.updateCustomer?.__typename === 'GraphQLError') {
        throw new Error(resp.updateCustomer.message);
      }
    }
  });
  const previousAddressForm = useForm<AddressFormValues, ZodValidator>({
    defaultValues: {
      vehicleRegistrationAddressAnswer: '', // This is only applicable to the current address
      street: exsitingPreviousAddress?.street ?? '',
      apartmentDetails: exsitingPreviousAddress?.apartmentDetails ?? '',
      city: exsitingPreviousAddress?.city ?? '',
      state: exsitingPreviousAddress?.state ?? '',
      zipCode: exsitingPreviousAddress?.zipCode ?? '',
      housingStatus: '',
      monthlyPayment: '',
      durationYears: exsitingPreviousAddress?.durationYears?.toString() ?? '',
      durationMonths: exsitingPreviousAddress?.durationMonths?.toString() ?? ''
    },
    validators: {
      onSubmit: ValidAddressSchema(t, 'PREVIOUS', 'IN_APP')
    },
    validatorAdapter: zodValidator(),
    onSubmit: async ({ value }) => {
      // We only update or create the previous address if it's required.
      // Also, the previous address form is only shown if the customer resided less than 2 years at their current address.
      if (atLeastTwoYearsAtCurrentAddress) {
        return;
      }

      const addressValues = {
        street: value.street,
        apartmentDetails: value.apartmentDetails,
        city: value.city,
        state: value.state,
        zipCode: value.zipCode,
        housingStatus:
          value.housingStatus.length > 0 ? value.housingStatus : undefined,
        monthlyPayment: value.monthlyPayment
          ? parseFloat(value.monthlyPayment)
          : undefined,
        durationYears: value.durationYears
          ? parseInt(value.durationYears)
          : undefined,
        durationMonths: value.durationMonths
          ? parseInt(value.durationMonths)
          : undefined
      };

      // If we already have an existing previous address, we'll update it
      if (exsitingPreviousAddress?.id || createdPreviousAddressId) {
        await updateAddressAction(
          exsitingPreviousAddress?.id ??
            createdPreviousAddressId ??
            'should-never-happen',
          addressValues
        );
      } else {
        const address = await createAddressAction(
          { ...addressValues, timelinePosition: 1 },
          transactionId
        );
        // We save this because the basic information form might still be invalid and we would not proceed.
        // So it would try to create it again
        setCreatedPreviousAddressId(address?.id ?? null);
      }
    }
  });
  const atLeastTwoYearsAtCurrentAddress = currentAddressForm.useStore(
    ({ values }) => {
      return (
        parseInt(values.durationYears ? values.durationYears : '0') * 12 +
          parseInt(values.durationMonths ? values.durationMonths : '0') >=
        24
      );
    }
  );
  const hasFilledOutCurrentAddress = currentAddressForm.useStore(
    ({ values }) => {
      return (
        // We cannot rely on the isValid prop here because it's only updated upon submission but we need to know whether it's valid to render the previous address form before that
        Boolean(values.street) &&
        Boolean(values.city) &&
        Boolean(values.state) &&
        Boolean(values.zipCode) &&
        Boolean(values.housingStatus) &&
        Boolean(values.monthlyPayment) &&
        (Boolean(values.durationMonths) || Boolean(values.durationYears)) // Either duration in months or years has to be provided
      );
    }
  );
  const handleGoToNext = async () => {
    try {
      setIsSubmitting(true);

      await Promise.all([
        basicInformationForm.handleSubmit(),
        previousAddressForm.handleSubmit(),
        currentAddressForm.handleSubmit()
      ]);

      // The forms are validated upon submission
      // So if they are valid, we know we can proceed
      if (
        basicInformationForm.state.isValid &&
        currentAddressForm.state.isValid &&
        (atLeastTwoYearsAtCurrentAddress || previousAddressForm.state.isValid)
      ) {
        await resetCustomerQuery(transactionId, userId);
        navigate(
          '/dashboard/:dealershipSlug/:transactionId/credit-application/employment',
          {
            params: {
              dealershipSlug,
              transactionId
            }
          }
        );
      } else if (
        basicInformationForm.state.isValid &&
        !atLeastTwoYearsAtCurrentAddress
      ) {
        toast.error(t('At least two years of address history are required.'));
      } else if (
        !currentAddressForm.state.isValid &&
        !currentAddressForm.state.values.vehicleRegistrationAddressAnswer
      ) {
        toast.error(
          t('Please specify where you are registering your vehicle.')
        );
      }
    } catch (error) {
      captureException(error);
      console.error(error);
      toast.error(t('An unexpected error happened'));
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleAnsweringRegistrationAsDifferent = () => {
    currentAddressForm.setFieldValue('street', '');
    currentAddressForm.setFieldValue('apartmentDetails', '');
    currentAddressForm.setFieldValue('city', '');
    currentAddressForm.setFieldValue('zipCode', '');
    currentAddressForm.setFieldValue('state', '');
  };

  return (
    <Fragment>
      <CreditApplicationBasicInformationForm
        form={basicInformationForm}
        isSubmitting={isSubmitting}
        environment="IN_APP"
      />

      <AddressForm
        form={currentAddressForm}
        isSubmitting={isSubmitting}
        type="CURRENT"
        dataTestId="credit-application-current-address-form"
        environment="IN_APP"
        onAnsweringRegistrationAsDifferent={
          handleAnsweringRegistrationAsDifferent
        }
      />

      <div
        className={classNames(
          'col-span-4 tablet:col-span-6 desktop:col-span-8 tablet:col-start-2 desktop:col-start-3', // Parent grid layout
          'flex flex-col space-y-spacing-01',
          {
            hidden:
              !hasFilledOutCurrentAddress || atLeastTwoYearsAtCurrentAddress
          }
        )}
      >
        <h2 className="heading-emphasized-02">{t('Previous Address')}</h2>

        <p>{t('Including the last two years of your housing is required.')}</p>
      </div>

      <AddressForm
        form={previousAddressForm}
        isSubmitting={isSubmitting}
        type="PREVIOUS"
        className={classNames({
          hidden: !hasFilledOutCurrentAddress || atLeastTwoYearsAtCurrentAddress
        })}
        environment="IN_APP"
      />

      <div
        className={classNames(
          'col-span-4 tablet:col-span-6 desktop:col-span-8 tablet:col-start-2 desktop:col-start-3', // Parent grid layout
          'flex flex-row justify-end pt-spacing-04'
        )}
      >
        <Button
          label={t('Next')}
          isLoading={isSubmitting}
          onClick={() => void handleGoToNext()}
          dataTestId="credit-application-address-next-button"
        />
      </div>
    </Fragment>
  );
};

const BasicAndAddressInformation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dealershipSlug, transactionId } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/credit-application/address'
  );
  const { data: meData } = useQuery(meQuery());
  const userId = meData?.me?.user?.id;
  const { data: customerData, isFetched: fetchedCustomerData } = useQuery(
    customerQuery(transactionId, meData?.me?.user?.id, dealershipSlug)
  );
  const handleGoBack = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: {
        dealershipSlug,
        transactionId
      }
    });
  };

  return (
    <div className="relative flex flex-col items-center bg-primary h-full max-h-screen overflow-y-scroll">
      <Header
        title={t('Credit Application')}
        className="w-full"
        leftElement={
          <Button
            label={t('Back')}
            variant="GHOST"
            size="LARGE"
            onClick={handleGoBack}
          />
        }
        rightElement={
          <Button
            label={t('Cancel')}
            variant="GHOST"
            size="LARGE"
            onClick={handleGoBack}
          />
        }
      />

      <div
        className={classNames(
          'mobile-screen-grid tablet:tablet-screen-grid desktop:desktop-screen-grid', // Grid layout
          'size-full space-y-spacing-05 tablet:space-y-spacing-06 py-spacing-05 tablet:py-spacing-06'
        )}
      >
        <div
          className={classNames(
            'col-span-4 tablet:col-span-8 desktop:col-span-10 desktop:col-start-2', // Parent grid layout
            'w-full relative flex flex-row justify-center'
          )}
        >
          <ProgressBar totalSteps={3} currentStep={1} />
        </div>

        {userId && fetchedCustomerData && (
          <Forms
            transactionId={transactionId}
            userId={userId}
            dealershipSlug={dealershipSlug}
            existingData={customerData}
          />
        )}

        {!fetchedCustomerData && (
          <div
            className={classNames(
              'col-span-4 tablet:col-span-8 desktop:col-span-10 desktop:col-start-2', // Parent grid layout
              'w-full h-96 relative flex flex-row justify-center items-center'
            )}
          >
            <Spinner color="GREY" size="LARGE" />
          </div>
        )}
      </div>
    </div>
  );
};

export default BasicAndAddressInformation;
