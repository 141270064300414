export const maskSSNRegex = (ssn: string) => {
  return ssn.replace(/^\d{3}-\d{2}/, "XXX-XX");
};

export const formatSSN = (ssn: string) => {
  // Remove all non-digit characters for a clean slate
  const digits = ssn.replace(/\D/g, "");

  // Break the string into parts and join with hyphens
  const parts = [];

  parts.push(digits.substring(0, 3)); // First three digits

  if (digits.length > 3) {
    parts.push(digits.substring(3, 5)); // Next two digits
  }

  if (digits.length > 5) {
    parts.push(digits.substring(5)); // Remaining digits
  }

  return parts.join("-").substring(0, 11); // Remove excess characters
};
